import { useQuery } from '@tanstack/react-query'
import { getDismissFeedback, getUser, shouldUserDoNPS } from './routes'

export const userKeys = {
  user: ['user'] as const,
  user_should_do_nps: (step: string) => ['user', 'userNPS', { step }] as const,
  user_feedback: (key?: string[]) => ['user', 'feedback', ...(key || [])] as const,
}

export const useGetUser = () =>
  useQuery({
    queryKey: ['user'],
    queryFn: () => getUser(),
    retryOnMount: true,
  })

export const useShouldUserDoNPS = ({ step }: { step: string }) =>
  useQuery({
    queryKey: userKeys.user_should_do_nps(step),
    queryFn: () => shouldUserDoNPS(step),
    enabled: false,
  })

export const useGetDismissFeedback = (key?: string[]) =>
  useQuery({
    queryKey: userKeys.user_feedback(key),
    queryFn: () => getDismissFeedback(key),
    enabled: !!key,
  })
