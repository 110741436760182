import { Outlet, ScrollRestoration } from 'react-router-dom'
import useAppWrapper from './useAppWrapper'

import 'src/styles/main.scss'
import ErrorBoundary from 'src/components/ErrorBoundary'
import SnackbarWrapper from '../../components/SnackbarWrapper'

function AppWrapper() {
  useAppWrapper()

  return (
    <ErrorBoundary>
      <SnackbarWrapper />

      <Outlet />
      <ScrollRestoration />
    </ErrorBoundary>
  )
}

export default AppWrapper
