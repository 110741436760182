import { styled, type Theme, Typography } from '@material-ui/core'

export const StyledText = styled(Typography)(({ theme }) => ({
  ...theme.typography.bodyNew,
  color: theme.ggPalette.obsidian[900],
  marginTop: theme.spacing(1.5),
  whiteSpace: 'pre-wrap',

  [theme.breakpoints.up('mobile')]: {
    marginTop: theme.spacing(2),
  },
}))

export const StyledImage = styled('img')<Theme, { $width?: number }>(({ theme, $width }) => ({
  display: 'block',
  boxSizing: 'border-box',
  margin: theme.spacing(3, 'auto', 0),
  height: 'auto',
  width: $width ?? '100%',
  objectFit: 'contain',
  maxWidth: 345,
}))
