import { useFlags } from 'launchdarkly-react-client-sdk'
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRegisterToken } from 'src/api/notifications/mutations'
import { showErrorToast } from 'src/utils/toasts'
import ExplainerPopup from './components/ExplainerPopup'
import { getAuth } from 'src/utils/auth'
import useFirebaseToken from './hooks/useFirebaseToken'
import { useUpdateUser } from 'src/api/user/mutations'
import { useGetUser } from 'src/api/user/queries'
import { captureException } from '@sentry/react'

interface ConfigContext {
  isLoading?: boolean
  isError?: boolean
  notificationPermissionStatus?: NotificationPermission | null
  token?: string | null
  getToken?: ({ isUserAction }: { isUserAction: boolean }) => Promise<void>
}

const isProduction = import.meta.env.MODE === 'production'

export const PushNotificationContext = createContext<ConfigContext>({})

interface FirebaseProviderProps {
  children: React.ReactNode
}

function PushNotificationProvider({ children }: FirebaseProviderProps) {
  const { pushNotifications } = useFlags()
  const [isLoading, setIsLoading] = useState(false)
  const [showEnabledModal, setShowEnabledModal] = useState(false)
  const [showBlockedModal, setShowBlockedModal] = useState(false)
  const authToken = getAuth()
  const calledRef = useRef(false)
  const isAuthorised = authToken?.decodedToken?.authorised

  const onPermissionDenied = useCallback(() => setShowBlockedModal(true), [])

  const { loadToken, notificationPermissionStatus, token } = useFirebaseToken({
    onPermissionDenied,
  })

  const { data: user, isFetching: isUserFetching } = useGetUser()

  const { mutateAsync: updateUser } = useUpdateUser()
  const { mutateAsync: registerToken, isError } = useRegisterToken()

  const getToken = useCallback(
    async ({ isUserAction }: { isUserAction: boolean }) => {
      if (!pushNotifications || (!isUserAction && user?.disable_notifications)) {
        return
      }

      setIsLoading(true)
      const token = await loadToken({ isUserAction })

      !isProduction && console.log('[Notification] Token:', token)

      if (!token) {
        setIsLoading(false)
        return
      }

      try {
        if (user?.disable_notifications) {
          await updateUser({ disable_notifications: false })
        }
      } catch (e) {
        console.error('[Notification] Error when updating user', e)
        captureException(e, {
          extra: {
            message: '[Notification] Error when updating user.',
          },
        })
        if (isUserAction) {
          showErrorToast(
            'Something went wrong when enabling notifications. Please try again from profile menu.',
          )
        }
        setIsLoading(false)
        return
      }

      try {
        await registerToken({ token })

        if (isUserAction) {
          // Show only if permission was requested
          setShowEnabledModal(true)
        }
      } catch (e) {
        console.error('[Notification] Error when registering token', e)
        captureException(e, {
          extra: {
            message: '[Notification] Error when registering token.',
          },
        })
        if (isUserAction) {
          showErrorToast(
            'Something went wrong when enabling notifications. Please try again from profile menu.',
          )
        }
        setIsLoading(false)
        return
      }

      setIsLoading(false)
    },
    [pushNotifications, user?.disable_notifications, loadToken, updateUser, registerToken],
  )

  useEffect(() => {
    if (user?.id) {
      // auto-call once for a user
      // else useEffect will auto-run for getToken's change again
      calledRef.current = false
    }
  }, [user?.id])

  useEffect(() => {
    if (isAuthorised && user?.id && !calledRef.current) {
      getToken({ isUserAction: false })
      calledRef.current = true
    }
  }, [getToken, isAuthorised, user?.id])

  const providerValue = useMemo(
    () => ({
      isLoading: isLoading || isUserFetching,
      isError,
      notificationPermissionStatus,
      token,
      getToken,
    }),
    [isLoading, isUserFetching, isError, notificationPermissionStatus, token, getToken],
  )

  return (
    <PushNotificationContext.Provider value={providerValue}>
      {children}

      <ExplainerPopup
        open={showEnabledModal}
        type="enabled"
        onClose={() => setShowEnabledModal(false)}
      />

      <ExplainerPopup
        open={showBlockedModal}
        type="blocked"
        onClose={() => setShowBlockedModal(false)}
      />
    </PushNotificationContext.Provider>
  )
}

export default PushNotificationProvider
