import axios from 'axios'
import UAParser from 'ua-parser-js'

interface RegisterDeviceRequest {
  token?: string | null
}

export const registerDevice = (data: RegisterDeviceRequest) => {
  if (!data.token) {
    throw new Error('Token is missing.')
  }

  const parser = new UAParser()
  const { browser, os } = parser.getResult()

  return axios.post('v3/notifications/register-device', {
    token: data.token,
    device_info: {
      platform: os.name,
      browser: `${browser.name} ${browser.version}`,
    },
  })
}

export const unregisterDevice = (data: RegisterDeviceRequest) => {
  if (!data.token) {
    throw new Error('Token is missing.')
  }

  return axios.post('v3/notifications/unregister-device', {
    token: data.token,
  })
}
