import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import * as api from 'src/api/ggAPI'
import { Mortgage } from 'src/types/Mortgage'

interface InitialState {
  isRequestContactModalOpen: boolean
}

export const initialState: InitialState = {
  isRequestContactModalOpen: false,
}

export const requestCallMarketplace = createAsyncThunk(
  'marketplace/requestCall',
  api.requestCallMarketplace,
)

const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    openModal: (state) => ({
      ...state,
      isRequestContactModalOpen: true,
    }),
    closeModal: (state) => ({
      ...state,
      isRequestContactModalOpen: false,
    }),
  },
})

export const { openModal, closeModal } = marketplaceSlice.actions

export default marketplaceSlice.reducer
