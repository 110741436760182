import dayjs from 'dayjs'
import findIndex from 'lodash/findIndex'

import { formatCurrencyWithCommaNoDP } from 'src/utils/_functions'
import { PFS_UPPER_TX_LIMIT, MODULR_UPPER_TX_LIMIT } from 'src/config/constants'
import { isValidEmail } from './functions'

/** @deprecated use `isValidEmail` from utils/functions.ts or `validateEmailWithMaxLength` instead to reduce risk of catastrophic backtracking. */
export const validateEmail = (email: string) => {
  const re = /^\w?([.-]?\w+)+@\w+([.-]+\w+)+$/
  return re.test(email)
}

/**
 * validateUrl
 * validates url
 * @param {string} input - string to be validated
 * @return {boolean} whether string passes validation
 */
export const validateUrl = (url: string) => {
  const re =
    /^(https?:\/\/)(www\.)?[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/

  return re.test(url)
}

/**
 * validateNumeric
 * validates numeric entry
 * @author Ravin Patel
 * @param {string} input - string to be validated
 * @return {boolean} whether string passes validation
 */
export const validateNumeric = (input) => {
  if (input === '') return false
  return !isNaN(input)
}

/**
 * validateQREntry
 * validates numeric entry
 * @author Ravin Patel
 * @param {string} input - number to be validated
 * @return {boolean} whether string passes validation
 */
export const validateQREntry = (input) => {
  if (input === '') return false
  const removeWhiteSpace = input.replace(/\s+/g, '')
  if (removeWhiteSpace.toString().length < 6) return false
  return !isNaN(removeWhiteSpace)
}

/**
 * validateRadioGroup
 * validates numeric entry
 * @author Ravin Patel
 * @param {string} input - string to be validated
 * @return {boolean} whether string passes validation
 */
export const validateRadioGroup = (input) => !(input === undefined || input === null)

/**
 * validateRequired
 * validates required input
 * @author Ravin Patel
 * @param {string} input - value to be validated
 * @return {boolean} whether value is entered
 */
export const validateRequired = (input) => {
  const trimmedValue = typeof input === 'string' ? input.trim() : input
  return (
    !!trimmedValue && trimmedValue !== '[undefined] undefined' && trimmedValue !== '[null] null'
  )
}

/**
 * validatePhone
 * validates phone number entry ie. (+00)00000...
 * @author Ravin Patel
 * @param {string} input - string to be validated
 * @return {boolean} whether string passes validation
 */
export const validatePhone = (input) => {
  const inputWithoutCountryCode = input ? input.substr(input.indexOf(' ') + 1) : ''
  const rawNumber = inputWithoutCountryCode.replace(/-| |\(|\)/g, '')

  if (rawNumber === '') return false
  return !isNaN(rawNumber) && rawNumber.length >= 7 && rawNumber.length <= 15
}

/**
 * validateMatching
 * validates input matches given argument
 * @author Ravin Patel
 * @param {string} input - value to be validated
 * @param {string} inputToMatch - value to match to
 * @return {boolean} whether values match
 */
export const validateMatching = (input, inputToMatch) => input === inputToMatch && Boolean(input)

/**
 * validateNotMatching
 * validates input not matching given argument
 * @author Ravin Patel
 * @param {string} input - value to be validated
 * @param {string} inputToMatch - value to compare to
 * @return {boolean} whether values dont match
 */
export const validateNotMatching = (input, inputToMatch) => input !== inputToMatch && !!input

export const validateNoOfCompanies = (input) => input > 0 && input < 21

/**
 * validateLettersOnly
 * validates letters only input
 * @param {string} input - value to be validated
 * @return {boolean} whether value is entered
 */
export const validateLettersOnly = (input) => !/[^a-zA-Z-\s]/.test(input)

/**
 * validateTotal
 * validates based on given cumulative total
 * @param {string} input - value to be validated
 * @param {number} total - cumulative total to check
 * @param {number} maxValue - maximum value to validate against
 * @return {boolean} whether value is entered
 */
export const validateTotal = (input, { total, maxValue }) => {
  return total <= maxValue
}

/**
 * validateMinimum
 * validates on a minimum character length
 * @param {string} value - value to be validated
 * @param {number} min - minimum character length allowed
 * @return {boolean} whether value is more than or equal to the minimum
 */
export const validateMinimum = (value, min) => {
  if (!value) return !value
  const valueWithoutSpace = value.replace(/ /g, '')

  return valueWithoutSpace.length >= min
}

export const validateMaximum = (value, max) => {
  return !value || (value && value.length <= max)
}

export const validateMinValue = (value, min) => {
  return value >= min
}

export const validateNoSpaces = (input) => !/[\s]/.test(input)

export const validateDate = (input) =>
  /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/.test(input)

export const validateOver18 = (input) => {
  const birthday = dayjs(input, 'DD/MM/YYYY')
  return dayjs().diff(birthday, 'year') >= 18
}

export const validateFutureDate = (input) => {
  const inputAsDayObj = dayjs(input)
  const result = inputAsDayObj.diff(dayjs(), 'day')
  return result >= 0
}

export const validateMinDate = (value, min) => {
  const isAfter = dayjs(value, 'DD/MM/YYYY').isAfter(dayjs(min, 'DD/MM/YYYY'))
  const isSame = dayjs(value, 'DD/MM/YYYY').isSame(dayjs(min, 'DD/MM/YYYY'))
  return min === '' || isAfter || isSame
}

export const validateInitialMinDate = (value) => {
  return dayjs(value).isAfter(dayjs('31/12/1899', 'DD/MM/YYYY'))
}

export const validateNoSpecial = (input) => !/[^a-zA-Z0-9,.'-\s]/.test(input)

export const validateNoSpecialPremise = (input: string) => !/[^a-zA-Z0-9#,.'-/£\s]/.test(input)

/**
 * Validates and flags all special characters in the text, returning a string with the flagged characters
 * @param {*} text
 * @returns a string with the flagged invalid characters
 */
export const validateAndFlagSpecialCharacter = (text: string) => {
  const flaggedCharacters: string[] = []
  text.split('').map((character) => {
    if (/[^a-zA-Z0-9#,.'-/\s]/.test(character)) {
      if (!flaggedCharacters.includes(character)) {
        flaggedCharacters.push(character)
      }
    }
  })
  if (flaggedCharacters.length > 0) {
    return flaggedCharacters.length > 1
      ? `${flaggedCharacters.join(', ')} are invalid characters`
      : `${flaggedCharacters[0]} is an invalid character`
  }
}

export const validateHasLowerCaseLetter = (input) => /[a-z]/.test(input)

export const validateHasNumber = (input) => /\d/.test(input)

export const validateHasCapitalLetter = (input) => /[A-Z]/.test(input)

export const validateHasSpecial = (input) => /[^a-zA-Z0-9,.'-\s]/.test(input)

export const validateLessThan50Chars = (input) => input?.length < 50

export const validateLessThan255Chars = (input) => input?.length < 255

export const validateReferenceLength = (input) =>
  /\S/.test(input) && input.replace(/[^A-Z0-9]/gi, '').length >= 6 && input.length <= 18

export const validateReference = (input) => !/[^0-9a-zA-Z-.&/ ]/.test(input)

export const validateBeneficiary = (input) => !/[^0-9a-zA-Z-.&/ ]/.test(input)

export const validateFirstCharAlphanumeric = (input) => /^[a-zA-Z0-9]/.test(input)

export const validateFirstCharAlphanumericWithTrimming = (input) =>
  /^[a-zA-Z0-9]/.test(input?.trim())

export const validateReferenceNoSameChars = (input) => !/(.)\1\1\1\1\1/.test(input)

export const validateSortCode = (input: string) => /^(\d{6}|\d{2}-\d{2}-\d{2})$/.test(input)

export const validateAccountNumberLength = (input) => input.length === 8

export const validateOTPNumeric = (input) => {
  const a = findIndex(input, (value) => isNaN(value))
  return a === -1
}

export const validateNoLetters = (input) => !/[A-Za-z]+/.test(input)

export const validateTransferDate = (input) => dayjs.isDayjs(input)

export const validateAmount = (input) =>
  !input.includes('-') && /^^[0-9]+(\.[0-9]{1,2})?$/.test(input)

export const validatePFSUpperLimit = (input) => Boolean(input <= PFS_UPPER_TX_LIMIT)

export const validateModulrUpperLimit = (input) => Boolean(input <= MODULR_UPPER_TX_LIMIT)

export const validateGreaterThanZero = (input) => {
  const inputWithoutCommas = input.toString().replace(/,/g, '')
  return Number(inputWithoutCommas) > 0
}

export const validateWhitespace = (value: string) => {
  if (typeof value !== 'string') return 'Invalid input value'
  return Boolean(value.trim()) || 'This is a required field'
}

export const validateNoFloat = (val) => {
  const valWithoutCommas = val.replace(/,/g, '')
  const valWithoutPoint = /^\d+(?:\.)?$/.test(valWithoutCommas)
  return valWithoutPoint && Number.isInteger(Number(valWithoutCommas))
}

export const validateYear = (value) => {
  return value.toString().length === 4 || 'Please enter valid year'
}

export const validateUKPostcode = (value) => {
  // https://stackoverflow.com/questions/164979/regex-for-matching-uk-postcodes
  return /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i.test(value)
}

export const validateNationalInsuranceNumber = (value: string) => {
  // https://www.gov.uk/national-insurance/your-national-insurance-number
  return /^(?!BG|GB|KN|NK|NT|TN|ZZ)(?![DFIQUV])[A-Z](?![DFIQUVO])[A-Z]\d{6}[A-Z]$/.test(value)
}

export const validateDigitsOnly = (value: string) => !/[^0-9.]/g.test(value)

export const VALIDATION_MESSAGES = {
  validateEmail: 'Invalid email',
  validateRequired: 'Required',
  validateMatching: 'No match',
  validateNotMatching: "Can't add self",
  validatePhone: 'Invalid number',
  validateNumeric: 'Number only',
  validateNoOfCompanies: 'No more than 20',
  validateLettersOnly: 'Only letters and spaces allowed',
  validateTotal: 'Max {{max}}',
  validateMinimum: 'Min length {{min}}',
  validateMaximum: 'Max length {{max}}',
  validateMinValue: 'Min value {{min}}',
  validateNoSpaces: 'No spaces',
  validateDate: 'DD/MM/YYYY',
  validateOver18: 'You need to be 18 or over to use GetGround',
  validateNoSpecial: 'Remove invalid characters',
  validateFutureDate: 'Completion date cannot be in the past.',
  validateMinDate: 'Less than {{min}}',
  validateRadioGroup: 'This is a required question',
  validateOTP: '6 digit number',
  validateLessThan50Chars: 'Sorry that is too long',
  validateLessThan255Chars: 'Character limit reached',
  validateReferenceLength: 'Between 6 and 18 characters',
  validateAccountNumberLength: 'Length should be 8 characters or numbers',
  validateReference: 'The only special characters we accept are &./-',
  validateReferenceNoSameChars: 'No more than 6 same consecutive characters allowed',
  validateNoLetters: 'Number only',
  validateAmount: 'Please correct amount (2 decimal max)',
  minimumExchange: 'Cannot exchange less than £150 at a time',
  validateRemoveInvalid: 'Remove invalid characters',
  minimumChars: 'Minimum of {{noOfChars}} characters ',
  maxChars: 'Maximum of {{noOfChars}} characters',
  validateGreaterThanZero: 'Property price cannot be £0',
  validateModulrUpperLimit: `You cannot send more than £${formatCurrencyWithCommaNoDP(
    MODULR_UPPER_TX_LIMIT,
  )} in a 24 hour period`,
  validatePFSUpperLimit: `You cannot send more than £${formatCurrencyWithCommaNoDP(
    PFS_UPPER_TX_LIMIT,
  )} in a 24 hour period`,
  validateFirstCharAlphanumericWithTrimming: 'First character must be a letter or number',
  validateFirstCharAlphanumeric: 'First character must be a letter or number',
  validateBeneficiary: 'The only special characters we accept are -.&/',
}

export function getMaxLengthError(max: number) {
  return `Maximum ${max} characters allowed`
}

function getMinLengthError(min: number) {
  return `Minimum ${min} characters allowed`
}

function findUnmatchingCharacters(inputString: string, regex: RegExp) {
  // Replace all matching characters with an empty string
  const matchingCharactersRemoved = inputString.replace(regex, '')

  // The remaining characters are the ones that did not match the regex
  if (matchingCharactersRemoved.length > 0) {
    const uniqueChars = Array.from(new Set(matchingCharactersRemoved.split('')))

    if (uniqueChars.length === 1) {
      return `${uniqueChars[0]} an invalid character`
    }

    return `${uniqueChars.join(', ')} are invalid characters`
  } else {
    return true
  }
}

// Address form validations
// Unit code
export function validateUnitCode(value: string) {
  if (value.length > 10) {
    return getMaxLengthError(10)
  }

  return findUnmatchingCharacters(value, /[0-9a-zA-Z'\-,/"().#\s]/g)
}

export function validateUTRNumber(value: string) {
  if (!validateDigitsOnly(value)) {
    return 'Only digits are allowed'
  }
  return value.length !== 10 ? 'Please enter 10 digit number' : true
}

// Street name
export function validateStreetName(value: string) {
  if (value.length > 100) {
    return getMaxLengthError(100)
  }

  return findUnmatchingCharacters(value, /[0-9a-zA-Z'\-,/"().\s]/g)
}

// City
export function validateCity(value: string) {
  if (value.length > 50) {
    return getMaxLengthError(50)
  }

  return findUnmatchingCharacters(value, /[0-9a-zA-Z'\-.\s]/g)
}

// Post code
export function validatePostCode(value: string) {
  if (value.length > 8) {
    return getMaxLengthError(8)
  }

  return validateUKPostcode(value) || 'Invalid postcode'
}

// Property price
export function validatePropertyPrice(value: number) {
  if (value < 10 ** 15) {
    // < 1,000,000,000,000,000
    return true
  }

  return getMaxLengthError(15)
}

export function validateAuthCode(value: string) {
  if (value.length !== 6) {
    return 'Please enter 6 characters code'
  }

  return findUnmatchingCharacters(value, /[0-9a-zA-Z]/g)
}
// validate name - like accountant name
export function validateName(value: string) {
  if (value.length > 256) {
    return getMaxLengthError(256)
  }

  if (value.trim().length < 2) {
    return getMinLengthError(2)
  }

  return findUnmatchingCharacters(value, /[a-zA-Z-\s]/g)
}

// validate email
export function validateEmailWithMaxLength(value: string) {
  if (value.length > 320) {
    // https://stackoverflow.com/q/386294
    return getMaxLengthError(320)
  }

  return isValidEmail(value) || 'Invalid email address'
}
