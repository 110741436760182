import { useState, createContext, ReactNode } from 'react'

export interface FeedbackModalConfig {
  open?: boolean
  type: string
  isFeedbackPositive?: boolean // used with default question and title
  title?: string
  questionLabel?: string
  toastMsg?: string
  dismissOnClose?: boolean
  callbackFn?: () => void // to trigger analytics events
}

interface FeedbackProps {
  modalConfig: FeedbackModalConfig
  setConfig: (config: Partial<FeedbackModalConfig>, reset?: boolean) => void
  toggleModal: (flag: boolean) => void
}

const initialState = {
  modalConfig: { open: false, type: '', isFeedbackPositive: true, dismissOnClose: true },
  setConfig: () => {},
  toggleModal: () => {},
}

const FeedbackModalContext = createContext<FeedbackProps>(initialState)

const FeedbackModalContextProvider = ({ children }: { children: ReactNode }) => {
  const [modalConfig, setModalConfig] = useState<FeedbackModalConfig>(initialState.modalConfig)

  const setConfig = (config: Partial<FeedbackModalConfig>, reset?: boolean) => {
    setModalConfig((prev) => (reset ? initialState.modalConfig : { ...prev, ...config }))
  }

  const toggleModal = (open: boolean) => {
    setModalConfig((prev) => ({ ...prev, open: open }))
  }

  return (
    <FeedbackModalContext.Provider value={{ modalConfig, setConfig, toggleModal }}>
      {children}
    </FeedbackModalContext.Provider>
  )
}

export { FeedbackModalContextProvider, FeedbackModalContext }
