import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { FeedbackModalContext } from 'src/state/context/FeedbackModalContext'
import { useDismissUserFeedback, useSendUserFeedback } from 'src/api/user/mutations'
import { trackSnowplow } from 'src/state/slices/user.slice'
import { showErrorToast, showSuccessToast } from 'src/utils/toasts'

const useUserFeedbackModal = () => {
  const dispatch = useDispatch()
  const { modalConfig, toggleModal } = useContext(FeedbackModalContext)
  const { mutateAsync: sendFeedback, isLoading: fetchSendFeedback } = useSendUserFeedback()
  const { mutateAsync: dismissFeedback, isLoading: fetchDismissFeedback } = useDismissUserFeedback()
  const isSubmitting = fetchSendFeedback || fetchDismissFeedback

  useEffect(() => {
    toggleModal(modalConfig?.open ?? true)
    if (modalConfig?.open !== false) {
      dispatch(
        trackSnowplow({
          category: 'feedback',
          action: 'load',
          label: 'feedback-modal',
        }),
      )
    }
  }, [modalConfig?.open])

  const onSubmit = async ({ notes }: { notes: string }) => {
    try {
      dispatch(
        trackSnowplow({
          category: 'feedback',
          action: 'submit',
          label: 'feedback-modal',
        }),
      )
      await sendFeedback({ step: modalConfig.type, notes })
      if (modalConfig.dismissOnClose) {
        await dismissFeedback(modalConfig.type)
      }
      showSuccessToast(modalConfig.toastMsg || 'Thank you for your feedback')
      modalConfig.callbackFn?.()
    } catch (e) {
      showErrorToast(e?.message || 'Failed to submit feedback')
    } finally {
      toggleModal(false)
    }
  }

  const onClose = async () => {
    dispatch(
      trackSnowplow({
        category: 'feedback',
        action: 'dismiss',
        label: 'feedback-modal',
      }),
    )
    if (modalConfig.dismissOnClose) {
      await dismissFeedback(modalConfig.type)
    }
    toggleModal(false)
  }

  return { modalConfig, isSubmitting, onSubmit, onClose }
}

export default useUserFeedbackModal
