import { InfoCircle } from '@gground/capcom.icons'
import {
  StyledButtonContainer,
  StyledContainer,
  StyledDescription,
  StyledLink,
  StyledTitle,
} from './PropertyOwner.styles'
import { Button } from '@gground/capcom.core'
import { useLocation } from 'react-router-dom'
import { createFinancialAccountUrl } from 'src/utils/ggUrl'
import { Tooltip } from '@material-ui/core'

interface Props {
  title: string
  description: string
  onCreateAccount: () => void
  onSubmit: () => void
  isLoading: boolean
}

const PropertyOwnerDetails = ({
  title,
  description,
  onSubmit,
  onCreateAccount,
  isLoading,
}: Props) => {
  const { pathname } = useLocation()

  return (
    <StyledContainer>
      <StyledTitle variant="h1">{title}</StyledTitle>
      <StyledDescription variant="body1">{description}</StyledDescription>
      <Tooltip
        title="The ownership structure should match to protect your privacy and prevent others from accessing your property’s financial transactions or misusing funds."
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        placement="top"
      >
        <StyledLink variant="subtitle2">
          <InfoCircle size={16} />
          Why should the ownership structure match?
        </StyledLink>
      </Tooltip>

      <StyledButtonContainer>
        <Button
          isLoading={isLoading}
          onClick={onSubmit}
          id="start-adding-property-btn"
          type="submit"
          variant="primary"
        >
          Confirm and continue
        </Button>
        <Button
          disabled={isLoading}
          onClick={onCreateAccount}
          href={createFinancialAccountUrl()}
          state={{ from: pathname }}
          id="open-financial-account-btn"
          variant="secondary"
        >
          Open a new Financial Account
        </Button>
      </StyledButtonContainer>
    </StyledContainer>
  )
}

export default PropertyOwnerDetails
