import axios from 'axios'
import { MortgageDetails, MortgageDetailsResponse } from 'src/types/Mortgage'
import { PropertyShortened, Property } from 'src/types/Property'
import { Valuation, ValuationRequest } from 'src/types/investments'
import CapitalGrowth from 'src/types/CapitalGrowth'
import Solicitor from 'src/types/Solicitor'
import {
  CompanyProperties,
  type MultiplePropertiesOnboarding,
} from 'src/types/MultiPropertyOnboarding'
import { MultiPropertyDocuments } from 'src/types/MultiPropertyDocument'

interface GetPropertiesResponse {
  properties: PropertyShortened[]
}

/* GET PROPERTIES LIST */
export const getProperties = (hasMultipleShareholders: boolean | null) => {
  return axios.get<GetPropertiesResponse>('/v3/properties', {
    params: { has_multiple_shareholders: hasMultipleShareholders || undefined },
  })
}

/* GET SINGLE PROPERTY */
export const getProperty = ({ id }: { id: number }) => axios.get<Property>(`/v3/properties/${id}`)

export interface PropertyUpdate {
  property_address: Partial<{
    address: {
      premise: string
      street: string
      thoroughfare: string | null
      posttown: string
      postcode: string
      country: string
    } | null
    skipped: boolean
    property_type: string | null
  }>
  purchase_details: Partial<{
    price: { amount_in_cents: number | null }
    completion_date: string | null
    using_mortgage: boolean | null
    process_status: string | null
    property_type: string | null
  }>
  is_complete: boolean
  is_co_owned?: boolean
}

export const updateProperty = (id: number, data: Partial<PropertyUpdate>) =>
  axios.patch<Property>(`/v3/properties/${id}`, data)

export interface PropertyPurchaseDetailsUpdate {
  completion_date: string | null
  is_completion_date_confirmed: boolean | null
  exchange_date: string | null
  is_exchange_date_confirmed: boolean | null
}

export const updatePropertyPurchaseDetails = (id: number, data: PropertyPurchaseDetailsUpdate) =>
  axios.patch<Property>(`/v3/properties/${id}/purchase_details`, data)

export interface PropertySourceOfFunds {
  source_of_funds: string
  source_of_funds_other_reason: string | null
}

export const updatePropertySourceOfFunds = (
  id: number,
  userID: number,
  data: PropertySourceOfFunds,
) => axios.patch(`/v3/property_purchases/${id}/users/${userID}`, data)

export const getPropertyMortgage = async (ppID?: number) => {
  if (!ppID) {
    return
  }

  const { current_mortgage_details } = await axios.get<MortgageDetailsResponse>(
    `v3/investments/${ppID}/mortgage_details`,
  )
  return current_mortgage_details
}

export const updatePropertyMortgage = (ppID: number, request: Partial<MortgageDetails>) =>
  axios.put(`v3/investments/${ppID}/mortgage_details`, request)

export const deletePropertyMortgage = (ppID: number) =>
  axios.delete(`v3/investments/${ppID}/mortgage_details`)

export const getLenders = async () => {
  const { lenders } = await axios.get('v3/mortgage_products/lenders')

  return [
    { value: null, name: '-' },
    ...lenders.map(({ id, label }: { id: number; label: string }) => ({
      value: id,
      name: label,
    })),
    { value: 'other', name: 'Other' },
  ]
}

export interface EquityReleaseRequest {
  first_name: string
  last_name: string
  phone_number: string
  property_purchase_id: number
}

export const createEquityRelease = (request: EquityReleaseRequest) =>
  axios.post('v3/mortgage_products/quote_equity_release', request)

/* GET PROPERTY PURCHASE VALUATIONS */
export const getPropertyValuations = (ppID: number) =>
  axios.get(`v3/investments/${ppID}/valuations`)

/* CREATE PROPERTY VALUATION */
export const createPropertyValuation = (ppID: number, data: ValuationRequest) =>
  axios.post<Valuation>(`v3/investments/${ppID}/valuations`, data)

/* GET CAPITAL GROWTH BY POSTCODE */
export const getCapitalGrowthByPostcode = (postcode: string): Promise<CapitalGrowth> =>
  axios.get(`v3/capital_rental_growth?postcode=${encodeURIComponent(postcode)}`)

export const updatePropertySolicitor = (ppID: number, solicitor: Solicitor) =>
  axios.put(`v3/property_purchases/${ppID}/solicitor`, solicitor)

export const sendPropertyDocumentsToSolicitor = (ppID: number) =>
  axios.post(`v3/property_purchases/${ppID}/solicitor/send_documents`)

/* Multi properties onboarding start */
export const createMultiplePropertiesOnboarding = (companyID: number): Promise<{ id: number }> =>
  axios.post('v3/onboardings/company_property', { company_id: companyID })

/* Multi properties onboarding update */
export const updateMultiplePropertiesOnboarding = ({
  companyPropertyID,
  data,
}: {
  companyPropertyID: number
  data: Partial<MultiplePropertiesOnboarding>
}): Promise<MultiplePropertiesOnboarding> =>
  axios.patch(`v3/onboardings/company_property/${companyPropertyID}`, data)

/* Multi properties GET */
export const getMultiplePropertiesOnboarding = (
  companyPropertyID: number,
): Promise<MultiplePropertiesOnboarding> =>
  axios.get(`v3/onboardings/company_property/${companyPropertyID}`)

/* Multi properties GET legal documents to sign */
export const getMultiplePropertiesDocuments = (
  companyPropertyID: number,
): Promise<MultiPropertyDocuments> =>
  axios.get(`v3/onboardings/company_property/${companyPropertyID}/documents`)

interface GetCompanyPropertiesResponse {
  company_properties: CompanyProperties[]
}

export const getCompanyProperties = (): Promise<GetCompanyPropertiesResponse> =>
  axios.get(`v3/onboardings/company_property`)
