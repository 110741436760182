import { StyledCloseIcon, StyledHeader } from './PropertyOwner.styles'
import { IconButton } from '@material-ui/core'
import PropertyOwnerDetails from './PropertyOwnerDetails'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { UserGroup } from 'src/types/UserGroup'
import { showErrorToast, showSuccessToast } from 'src/utils/toasts'
import { landlordWalletOnboardingPropertyAddress, todayUrl } from 'src/utils/ggUrl'
import { useCreateUserGroupProperty } from 'src/api/landlord/mutations'
import useLandLordOnboardingAnalytics from '../../hooks/useLandLordOnboardingAnalytics'
import SEO from 'src/components/Seo'

const PropertyOwner = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const {
    crossIconOwnerAnalytics,
    addSharedOwnerShipPropertyAnalytics,
    addSoleOwnerShipPropertyAnalytics,
    clickOpenNewFinancialAccountRedirectAnalytics,
  } = useLandLordOnboardingAnalytics()

  const { userGroup } = useOutletContext<{
    userGroup: UserGroup
  }>()

  const isCoOwner = userGroup?.is_co_owned
  const { mutateAsync: createUserGroupProperty, isLoading: isSubmittingUserGroupProperty } =
    useCreateUserGroupProperty()

  const onSubmit = async () => {
    if (isCoOwner) {
      addSharedOwnerShipPropertyAnalytics()
    } else {
      addSoleOwnerShipPropertyAnalytics()
    }
    try {
      const property = await createUserGroupProperty({ id: userGroup?.id })

      showSuccessToast('Property added successfully')
      navigate(landlordWalletOnboardingPropertyAddress(userGroup.id, property.id))
    } catch {
      showErrorToast('Error adding new property. Please try again.')
    }
  }

  const goBack = () => {
    crossIconOwnerAnalytics()
    navigate(state?.from || todayUrl())
  }

  return (
    <>
      <SEO title="Property Ownership - GetGround" />

      <StyledHeader>
        <IconButton onClick={goBack}>
          <StyledCloseIcon size={32} />
        </IconButton>
      </StyledHeader>
      <PropertyOwnerDetails
        title={
          isCoOwner
            ? 'Is the property owned by the same people?'
            : 'Are you the only owner of this property?'
        }
        description={
          isCoOwner
            ? 'If this property is not owned by the same people as the one you previously added, we recommend opening a new Financial Account.'
            : 'If the property has additional owners, we recommend opening a new Financial Account.'
        }
        onSubmit={onSubmit}
        onCreateAccount={clickOpenNewFinancialAccountRedirectAnalytics}
        isLoading={isSubmittingUserGroupProperty}
      />
    </>
  )
}

export default PropertyOwner
