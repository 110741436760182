import JwtDecode, { JwtPayload } from 'jwt-decode'
import dayjs from 'dayjs'
import { initializeLogRocket } from './functions'
import Cookies from 'js-cookie'
import { WS_FLOW_SIGNUP_IN_PROGRESS } from 'src/config/constants'
import { removeFCMToken } from 'src/components/PushNotificationProvider/utils'

type DecodedToken = JwtPayload & {
  user_id: number
  employee_id: number
  password_reset: boolean
  authorised: boolean
  email: string
  consultant_id: number
  is_web_session_user: boolean
}

export type Auth = {
  timestamp: string
  token: string
  decodedToken: DecodedToken
  isWebSessionUser?: boolean
}

export const AUTH_STORAGE_KEY = 'jwt'

const decodeToken = (token: string): DecodedToken | null => {
  if (!token) return null
  try {
    return JwtDecode<DecodedToken>(token)
  } catch {
    return null
  }
}

const isJwtToken = (token: string): boolean => {
  try {
    const decoded = decodeToken(token)
    return !!decoded && !decoded.is_web_session_user
  } catch {
    return false
  }
}

export const isAuthorisedToken = (token: string): boolean => {
  const decoded = decodeToken(token)
  return !!decoded && decoded.authorised
}

export const getJwtToken = (): string | null => {
  const token = window.localStorage?.getItem(AUTH_STORAGE_KEY)
  return token && isJwtToken(token) ? token : null
}

export const getWebSessionToken = (): string | null => {
  const token = Cookies.get('web_session_token')
  return token || null
}

export const decodeAuthStorage = (data: string | null): Auth | null => {
  if (!data) return null

  try {
    const parsed: Omit<Auth, 'decodedToken'> = JSON.parse(data)
    const decodedToken = decodeToken(parsed.token)
    if (!decodedToken) return null

    return {
      ...parsed,
      decodedToken,
    }
  } catch {
    return null
  }
}

export const setAuth = async (
  token: string,
  { shouldRemoveFCMToken = false } = {},
): Promise<void> => {
  if (isJwtToken(token)) {
    const current = decodeAuthStorage(getJwtToken())
    const timestamp = current?.token === token ? current?.timestamp : dayjs().format()
    window.localStorage?.setItem(AUTH_STORAGE_KEY, JSON.stringify({ token, timestamp }))
    initializeLogRocket()
  }

  // Do not call this from protected routes
  if (shouldRemoveFCMToken) {
    // Remove any existing tokens before login
    await removeFCMToken()
  }
}

export const saveRememberDeviceToken = (token: string): void => {
  const now = dayjs()
  localStorage.setItem('remember_device_token', JSON.stringify({ token, timestamp: now }))
}

export const setAuthCookie = (token: string): void => {
  const expires = dayjs().add(365, 'day').toDate()
  Cookies.set('web_session_token', token, { expires })
}

export const getAuth = (): Auth | null => {
  const jwtToken = getJwtToken()
  const webSessionToken = getWebSessionToken()

  if (jwtToken) {
    return decodeAuthStorage(jwtToken)
  } else if (webSessionToken) {
    const decodedToken = decodeToken(webSessionToken)
    if (!decodedToken) return null

    return {
      token: webSessionToken,
      timestamp: dayjs().format(),
      decodedToken,
      isWebSessionUser: true,
    }
  }

  return null
}

export const isAuthenticatedUser = (): boolean => {
  const token = getJwtToken()
  return token !== null
}

export const isWebSessionUser = (): boolean => {
  const token = getWebSessionToken()
  const jwt = getJwtToken()
  return token !== null && jwt === null
}

export const clearAuth = () => {
  window.localStorage.removeItem(AUTH_STORAGE_KEY)
  Cookies.remove('web_session_token')
  Cookies.remove(WS_FLOW_SIGNUP_IN_PROGRESS)
}
