import Helmet from 'react-helmet'

interface SEOProps {
  title?: string
  description?: string
  structuredData?: string
  noIndex?: boolean
}
const isProduction = import.meta.env.MODE === 'production'

function SEO({ title = '', description = '', structuredData = '', noIndex = false }: SEOProps) {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}

      {title && <meta name="og:title" content={title} />}
      {description && <meta name="og:description" content={description} />}
      {(!isProduction || noIndex) && <meta name="robots" content="noindex" />}

      {structuredData && <script type="application/ld+json">{structuredData}</script>}
    </Helmet>
  )
}

export default SEO
