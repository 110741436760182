import {
  GCLID_STORAGE_KEY,
  UTM_CAMPAIGN_STORAGE_KEY,
  UTM_CONTENT_STORAGE_KEY,
  UTM_MEDIUM_STORAGE_KEY,
  UTM_SOURCE_STORAGE_KEY,
  UTM_TERM_STORAGE_KEY,
} from 'src/hooks/useSaveParams'

export type TrackingParameters = {
  gclid?: string
  utm_campaign?: string
  utm_content?: string
  utm_medium?: string
  utm_source?: string
  utm_term?: string
}

export function fetchTrackingParameters(): TrackingParameters {
  return {
    gclid: sessionStorage?.getItem?.(GCLID_STORAGE_KEY) ?? undefined,
    utm_campaign: sessionStorage?.getItem?.(UTM_CAMPAIGN_STORAGE_KEY) ?? undefined,
    utm_content: sessionStorage?.getItem?.(UTM_CONTENT_STORAGE_KEY) ?? undefined,
    utm_medium: sessionStorage?.getItem?.(UTM_MEDIUM_STORAGE_KEY) ?? undefined,
    utm_source: sessionStorage?.getItem?.(UTM_SOURCE_STORAGE_KEY) ?? undefined,
    utm_term: sessionStorage?.getItem?.(UTM_TERM_STORAGE_KEY) ?? undefined,
  }
}
