import { useCallback, useEffect, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { getAuth } from 'src/utils/auth'
import { getNotificationPermission } from '../utils'
import { getNotificationPermissionAndToken, type NotificationOptions } from '../token-utils'
import { useGetUser } from 'src/api/user/queries'
import { captureMessage } from '@sentry/react'

interface UseFirebaseTokenProps {
  onPermissionDenied?: () => void
}

const useFirebaseToken = ({ onPermissionDenied }: UseFirebaseTokenProps) => {
  const [notificationPermissionStatus, setNotificationPermissionStatus] =
    useState<NotificationPermission | null>()
  const [token, setToken] = useState<string | null>(null)
  const { pushNotifications } = useFlags()
  const { data: user } = useGetUser()

  const isAuthorised = getAuth()?.decodedToken?.authorised

  useEffect(() => {
    if (!isAuthorised || !user?.id) {
      setToken(null)
    }
  }, [isAuthorised, token, user?.id])

  const loadToken = useCallback(
    async ({ isUserAction = true }: NotificationOptions) => {
      if (!pushNotifications) return null

      const fcmToken = await getNotificationPermissionAndToken({ isUserAction, onPermissionDenied })

      const notificationPermission = getNotificationPermission()
      setNotificationPermissionStatus(notificationPermission)
      setToken(fcmToken || null)

      // Check for granted permission only
      // There are inconsistencies when browser auto-deny popup which keeps the
      // Notification.permission as `default` but requestPermission returns `denied`
      if (notificationPermission === 'granted') {
        if (!fcmToken) {
          console.info('Unable to load token.')
          captureMessage('[Notification] Unable to load token.', {
            extra: {
              notification: {
                notificationPermission,
                fcmToken,
              },
            },
          })
          return null
        }

        return fcmToken
      }

      return null
    },
    [onPermissionDenied, pushNotifications],
  )

  return { token, loadToken, notificationPermissionStatus }
}

export default useFirebaseToken
