import { ModalDrawer } from '@gground/capcom.core'
import useBreakpoint from 'src/hooks/useBreakpoint'
import { StyledImage, StyledText } from './ExplainerPopup.styles'

import notificationEnabledImage from 'src/assets/images/push-notifications/notifications-enabled.png'
import notificationBlockedImage from 'src/assets/images/push-notifications/notifications-blocked.png'
import { useEffect } from 'react'
import { useTypedDispatch } from 'src/state/reducers'
import { trackSnowplow } from 'src/state/slices/user.slice'

const enabledPopupData = {
  title: 'Notifications enabled',
  text: 'Great! You can disable notifications anytime from the Profile menu.',
  image: notificationEnabledImage,
  imageAlt: 'Illustration to show notifications from profile menu.',
}

const blockedPopupData = {
  title: 'Notifications blocked',
  text: 'To enable notifications, you will need to re-enable GetGround in your browser settings. This is often found under "Privacy and Security" or "Notifications".',
  image: notificationBlockedImage,
  imageAlt: 'Illustration of browser settings to enable notifications.',
}

interface ExplainerPopupProps {
  open: boolean
  type?: 'enabled' | 'blocked'
  onClose: () => void
}

function ExplainerPopup({ open, type = 'enabled', onClose }: ExplainerPopupProps) {
  const dispatch = useTypedDispatch()
  const isMobile = useBreakpoint('mobile')

  const { title, text, image, imageAlt } = type === 'enabled' ? enabledPopupData : blockedPopupData

  useEffect(() => {
    if (!open) return

    dispatch(
      trackSnowplow({
        category: 'push-notifications',
        action: 'load',
        label: type === 'enabled' ? 'notifications-enabled-modal' : 'notifications-blocked-modal',
      }),
    )
  }, [dispatch, open, type])

  function onModalClose() {
    dispatch(
      trackSnowplow({
        category: 'push-notifications',
        action: 'close',
        label: type === 'enabled' ? 'notifications-enabled-modal' : 'notifications-blocked-modal',
      }),
    )

    onClose()
  }

  return (
    <ModalDrawer
      open={open}
      title={title}
      isMobile={isMobile}
      onClose={onModalClose}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'xs',
      }}
      drawerProps={{
        showCloseIcon: true,
      }}
    >
      <StyledText>{text}</StyledText>

      <StyledImage src={image} alt={imageAlt} />
    </ModalDrawer>
  )
}

export default ExplainerPopup
