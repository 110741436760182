import { XClose } from '@gground/capcom.icons'
import { styled, Typography } from '@material-ui/core'

export const StyledCloseIcon = styled(XClose)(({ theme }) => ({
  color: theme.ggPalette.obsidian[600],
}))

export const StyledHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(2, 3),
}))

export const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 3),
  minHeight: 'calc(100vh - 70px)',
  flexDirection: 'column',
  maxWidth: '452px',
  margin: '0 auto',
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'eczar',
  fontSize: 36,
  fontWeight: 600,
  color: theme.ggPalette.teal[800],
  lineHeight: '43px',
  marginBottom: theme.spacing(2),
  textAlign: 'center',
}))

export const StyledDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.bodyNew,
  marginBottom: theme.spacing(1),
  textAlign: 'center',
}))

export const StyledLink = styled(Typography)(({ theme }) => ({
  color: theme.ggPalette.special.link,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  cursor: 'pointer',
}))

export const StyledButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  margin: theme.spacing(5, 0),
  width: '100%',

  [theme.breakpoints.up('mobile')]: {
    flexDirection: 'row',
  },
}))
