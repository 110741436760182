import { showErrorToast } from 'src/utils/toasts'
import { fcmConfig, getNotificationPermission, messaging } from './utils'
import { getToken } from 'firebase/messaging'
import { trackSnowplow } from 'src/state/slices/user.slice'
import { captureException, captureMessage } from '@sentry/react'
import store from 'src/state/store'

const MAX_ATTEMPTS = 3

export const fetchToken = async (isUserAction = false) => {
  try {
    if (!window.isSecureContext) {
      captureMessage('[Notification][SW] Service worker requires a secure context (https).')
      console.log('[Notification][SW] Service worker requires a secure context (https).')
      return null
    }

    if (!('serviceWorker' in navigator)) {
      captureMessage('[Notification][SW] Service worker is not supported.')
      console.log('[Notification][SW] Service worker is not supported.')
      return null
    }

    const fcmMessaging = await messaging()

    if (fcmMessaging) {
      let attempts = 0

      // We are retrying as sometimes the serviceWorker is not accessible immediately and
      // throws this error `Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker`
      while (attempts < MAX_ATTEMPTS) {
        try {
          const token = await getToken(fcmMessaging, {
            vapidKey: fcmConfig.vapidPublicKey,
          })

          return token
        } catch (e) {
          attempts++

          if (attempts >= MAX_ATTEMPTS) {
            isUserAction && showErrorToast('Something went wrong with notifications!')
            captureException(e, {
              extra: {
                message: '[Notification] An error occurred when getting token.',
              },
            })
            console.log('[Notification] An error occurred when getting token.', e)
            return null
          }
        }
      }
    }

    return null
  } catch (e) {
    isUserAction && showErrorToast('Something went wrong with notifications!')
    captureException(e, {
      extra: {
        message: '[Notification] An error occurred.',
      },
    })
    console.log('[Notification] An error occurred.', e)
    return null
  }
}

export interface NotificationOptions {
  isUserAction?: boolean
  onPermissionDenied?: () => void
}

export const getNotificationPermissionAndToken = async ({
  isUserAction = true,
  onPermissionDenied,
}: NotificationOptions) => {
  const notificationPermission = getNotificationPermission()

  if (!notificationPermission) {
    isUserAction && showErrorToast('This browser does not support notifications.')
    return null
  }

  if (notificationPermission === 'granted') {
    // Permission is already granted, mostly when user re-visits the site or comes from profile-menu
    return await fetchToken(isUserAction)
  }

  if (notificationPermission === 'denied') {
    if (isUserAction) {
      // Show only if permission was requested
      onPermissionDenied?.()
    }

    return null
  }

  if (!isUserAction) {
    // Don't ask for permission if user hasn't asked for it
    return null
  }

  const permission = await Notification?.requestPermission()

  if (permission === 'granted') {
    store.dispatch(
      trackSnowplow({
        category: 'push-notifications',
        action: 'allow-notifications',
        label: 'browser-notification-prompt',
      }),
    )

    console.log('[Notification] Permission granted.')
    return await fetchToken(true)
  } else if (permission === 'denied') {
    onPermissionDenied?.()

    store.dispatch(
      trackSnowplow({
        category: 'push-notifications',
        action: 'block-notifications',
        label: 'browser-notification-prompt',
      }),
    )

    console.log('[Notification] Permission denied.')
  } else {
    store.dispatch(
      trackSnowplow({
        category: 'push-notifications',
        action: 'dismiss-notifications',
        label: 'browser-notification-prompt',
      }),
    )

    console.log('[Notification] Permission dismissed.')
  }

  return null
}
