import axios from 'axios'
import Document from 'src/types/Document'
import { CompanyShortened, CompanyOverview } from 'src/types/Company'
import { Shareholder } from 'src/types/CompanyShareholder'
import GGPayment from 'src/types/GGPayment'
import CompanyDocumentsCategory from 'src/types/CompanyDocumentCategory'
import { Address } from 'src/types/Address'
import CompanyType from 'src/types/CompanyType'
import Partner from 'src/types/Partner'
import type ShareholderData from 'src/types/shareholder'

const mutateTimestamp = (timestamp: number | null) =>
  timestamp ? Number(timestamp * 10 ** 9) : null

export const getCompany = (companyId: number) =>
  axios.get(`v2/companies/${companyId}/overview`).then<CompanyOverview>((res) => ({
    ...res,
    created_at: mutateTimestamp(res.created_at),
    updated_at: mutateTimestamp(res.updated_at),
    deleted_at: mutateTimestamp(res.deleted_at),
  }))

export const getCompanies = () => axios.get<{ companies: CompanyShortened[] }>('v3/companies')

export const getBilling = (companyID: number) =>
  axios.get<{
    due_date: number
    payment_method: string
    amount_in_cents: number
  }>(`v3/companies/${companyID}/subscription`)

export const getDocuments = (companyId: number) =>
  axios.get<Document[]>(`/v3/companies/${companyId}/files`)

export const getDocumentsCategories = () =>
  axios.get<{
    file_categories: CompanyDocumentsCategory[]
  }>('v3/constants/file_categories')

export const getExpensesCategories = () => axios.get('v3/constants/expense_categories')

export const deleteDocuments = (companyID: number, ids: string[]) =>
  axios.delete(`v3/companies/${companyID}/files?id=${ids.join(',')}`)

export const updateDocuments = (companyID: number, documents: Document[]) => {
  return axios.put<Document[]>(`/v3/companies/${companyID}/files`, {
    file_objects: documents.map((doc) => ({
      id: doc.id,
      title: doc.title,
      category: doc.category,
      document_date: doc.document_date,
      currency: doc.currency,
      description: doc.description,
      expense_category: doc.expense_category,
      merchant_name: doc.merchant_name,
      amount_in_cents: doc.payment_amount,
    })),
  })
}

export interface CompanyProduct {
  id: number
  next_step:
    | 'CHOOSE_COMPANY_STRUCTURE'
    | 'ADD_PERSONAL_DETAILS'
    | 'COMPLETE_ID_CHECK'
    | 'ADD_COMPANY_DETAILS'
    | 'PAY_FOR_COMPANY_SETUP'
    | 'REMIND_YOUR_SHAREHOLDERS_TO_ACCEPT'
    | 'WAITING_COMPANY_FORMATION'
    | 'SIGN_YOUR_DOCUMENTS'
    | 'REMIND_SHAREHOLDERS_TO_SIGN'
    | 'WAITING_ACCOUNT_SETUP'
    | 'COMPLETE_COMPANY'
  property_ids: number[]
  is_payment_submitted: boolean
  company_type: 'SPV' | 'HOLDCO'
  company_name: string | null
}

export const getCompanyProducts = () =>
  axios.get<{ company_products: CompanyProduct[] }>('v3/company_products', {
    params: { is_completed: false },
  })

export const getCompanyProduct = ({ companyProductID }: { companyProductID: number }) =>
  axios.get<CompanyProduct>(`v3/company_products/${companyProductID}`)

export const updateCompanyAlias = (companyID: number, alias: string) =>
  axios.patch(`v3/companies/${companyID}`, { alias })

export interface CreateProduct {
  is_holding_co: boolean
  parent_company_id: number
}

export const createCompanyProduct = (data: CreateProduct) =>
  axios.post<{ investment_id: number }>(`v3/products`, { ...data, product_type: 'COMPANY' })

export const deleteCompanyProduct = ({ companyProductID }: { companyProductID: number }) =>
  axios.delete('v3/products', { data: { product_id: companyProductID, product_type: 'COMPANY' } })

export const getGGPayments = (companyID: number) =>
  axios.get<GGPayment[]>(`v3/companies/${companyID}/payments`)

export interface ShortenedCompanyOnboarding {
  id: number
  company_name: string | null
  created_at: string
  is_payment_submitted: boolean
}
interface CompanyOnboardings {
  company_onboardings: ShortenedCompanyOnboarding[]
}

export const getOnboardingStockCompanies = (): Promise<CompanyOnboardings> =>
  axios.get('v3/company_onboardings')

export const deleteOnboardingStockCompany = (id: number) =>
  axios.delete(`v3/company_onboardings/${id}`)

export const createOnboardingStockCompany = ({ packageId }: { packageId?: number } = {}) =>
  axios.post<{ id: number }>('v3/company_onboardings', {
    ...(packageId && { package_id: packageId }),
  })

type ShareholdersResponse = { shareholders: Shareholder[] }

export const getCompanyShareholders = (companyID: number) =>
  axios
    .get<ShareholdersResponse>(`v3/companies/${companyID}/shareholders_kyc_details`)
    .then((response) => response.shareholders || [])

type ShareholderDetails = {
  property_purchase_id: number
  address: Address
  price: {
    amount_in_cents: number
    currency: string
  }
  shareholders: ShareholderData[]
  invitee: {
    first_name: string
    last_name: string
    email: string
    is_existing_user: boolean
    require_source_of_funds: boolean
  }
  inviter: {
    first_name: string
    last_name: string
    email: string
  }
  holding_company_details: {
    company_name: string
    registration_number: string
  }
  holding_company_subsidiaries: {
    company_name: string
    price: {
      amount_in_cents: number
      currency: string
    }
    property_address: Address
  }[]
  company_type: CompanyType
  has_fees: boolean
  partner: Partner
  company_name: string
  registration_number: string
  company_id: number
  is_stock: boolean
}

export const retrievePPForShareholder = (token?: string) => {
  if (!token) return Promise.resolve(null)
  return axios.get<ShareholderDetails>(`v3/invitations/details?token=${token}`, { useAPIKey: true })
}
