import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Big from 'big.js'

import * as api from 'src/api/ggAPI'
import PropertyListing from 'src/types/PropertyListing'
import ListingFilter from 'src/types/ListingFilter'
import BrowsePropertyQuery from 'src/types/BrowsePropertyQuery'
import AreaHighlight from 'src/types/AreaHighlight'
import { ListingProperty, OtherListings, Location, ValueObject } from 'src/types/ListingProperty'
import {
  BUILD_TYPE_DEFAULT,
  COMPLETION_DATE_DEFAULT,
  DEVELOPMENT_DEFAULT,
  LOCATION_DEFAULT,
} from 'src/components-pages/BrowseProperty/Filters/SelectOptions'
import ListingInvestmentsCalculation from 'src/types/ListingInvestmentsCalculation'
import ListingInvestmentsCalculationQuery from 'src/types/ListingInvestmentsCalculationQuery'

interface InitialState {
  filters: ListingFilter[]
  isLoading: boolean
  listings: PropertyListing[]
  listingProperty: null | ListingProperty
  queryValues: BrowsePropertyQuery | null
  priceSelected: string
  priceMinSelected: string | null
  priceMaxSelected: string | null
  depositSelected: string
  depositMaxSelected: string | null
  locationsSelected: Location[]
  completionDateSelected: ValueObject[]
  buildTypeSelected: string
  developmentSelected: string
  sortByQuery: string
  sortByValue: string
  otherListings: {
    list: OtherListings[]
    total: number
    isLoading: boolean
  }
  size: number
  listingProgress: 'LISTING_CHOSEN' | 'PACKAGE_CHOSEN' | 'CALL_REQUESTED' | null
  totalListings: number
  allUserListings: ListingProperty[]
  userSelectedProperties: ListingProperty[]
  isSelectedPropertiesModalOpen: boolean
  totalSavedListings: number
  allPropertiesViewMode: 'list' | 'grid' | 'table'
  savedPropertiesViewMode: 'list' | 'grid' | 'table'
  investmentsCalculation: ListingInvestmentsCalculation
  investmentsCalculationParams: ListingInvestmentsCalculationQuery
  browsePropertyCurrentListingSelectedID: string | null
  listingAreaHighlightSelected: AreaHighlight | null
  areaHighlights: AreaHighlight[]
}

export const initialState: InitialState = {
  allUserListings: [],
  depositSelected: 'Any',
  filters: [],
  isLoading: true,
  isSelectedPropertiesModalOpen: false,
  listings: [],
  listingProgress: null,
  listingProperty: null,
  priceMinSelected: null,
  priceMaxSelected: null,
  depositMaxSelected: null,
  buildTypeSelected: BUILD_TYPE_DEFAULT,
  developmentSelected: DEVELOPMENT_DEFAULT,
  otherListings: {
    list: [],
    total: 0,
    isLoading: true,
  },
  priceSelected: 'Any',
  queryValues: null,
  locationsSelected: [{ label: LOCATION_DEFAULT, value: LOCATION_DEFAULT }],
  completionDateSelected: [{ value: COMPLETION_DATE_DEFAULT }],
  size: 12,
  sortByQuery: '',
  sortByValue: 'featured',
  totalListings: 0,
  totalSavedListings: 0,
  userSelectedProperties: [],
  allPropertiesViewMode: 'list',
  savedPropertiesViewMode: 'table',
  investmentsCalculation: {
    total_return_in_cents: 0,
    total_investment_in_cents: 0,
    annual_return_percentage: 0,
    gross_yield_percentage: 0,
    first_year_rental_income_pre_tax_in_cents: 0,
  },
  investmentsCalculationParams: {
    time_period_in_years: 10,
    starting_ltv_percentage_in_bps: 0,
    price_in_cents: 0,
    rental_income_in_cents: 0,
    rental_growth_forecast_in_bps: 0,
    capital_growth_forecast_in_bps: 0,
  },
  browsePropertyCurrentListingSelectedID: null,
  listingAreaHighlightSelected: null,
  areaHighlights: [],
}

export const getAllListings = createAsyncThunk(
  'listingProperties/getAllListings',
  (
    { savedListings, listingsSize }: { savedListings?: boolean; listingsSize?: number },
    { getState, rejectWithValue },
  ) => {
    const { size } = getState().listingProperty
    return api
      .getAllListings({ size: listingsSize ?? size, savedListings })
      .catch(({ response: { status } }) => rejectWithValue({ code: status }))
  },
)

export const getFilteredListings = createAsyncThunk(
  'listingProperties/getFilteredListings',
  (query: string, { getState, rejectWithValue }) => {
    const { size } = getState().listingProperty

    return api
      .getFilteredListings(query, size)
      .catch(({ response: { status } }) => rejectWithValue({ code: status }))
  },
)

export const getSinglePropertyListing = createAsyncThunk(
  'listingProperties/getSingle',
  (id: string, { rejectWithValue }) =>
    api
      .getSinglePropertyListing(id)
      .catch(({ response: { status } }) => rejectWithValue({ code: status })),
)

export const getListingsFilters = createAsyncThunk(
  'listingProperties/getListingsFilters',
  (_, { rejectWithValue }) =>
    api.getListingsFilters().catch(({ response: { status } }) => rejectWithValue({ code: status })),
)

export const getOtherPropertyListings = createAsyncThunk(
  'listingProperties/getOtherPropertyListings',
  api.getOtherPropertyListings,
)

export const getAllUserListings = createAsyncThunk(
  'listingProperties/getAllUserListings',
  ({ userID }: { userID: number }) => api.getAllUserListings({ userID }),
)

export const saveListing = createAsyncThunk(
  'listingProperties/saveListing',
  ({ listingID }: { listingID: number; isSingleListing?: boolean }) =>
    api.saveListing({ listingID }),
)

export const removeSavedListing = createAsyncThunk(
  'listingProperties/removeSavedListing',
  ({
    listingID,
  }: {
    listingID: number
    isSingleListing?: boolean
    isSavedProperties?: boolean
  }) => {
    return api.removeSavedListing({ listingID })
  },
)

export const getListingInvestmentsCalculation = createAsyncThunk(
  'listingProperties/getListingInvestmentsCalculation',
  ({ listingID, params }: { listingID: number; params: ListingInvestmentsCalculationQuery }) =>
    api.getListingInvestmentsCalculation({ listingID, params }),
)

export const getListingAreaHighlights = createAsyncThunk(
  'listingProperties/getListingAreaHighlights',
  ({ listingID }: { listingID: number }) => api.getListingAreaHighlights({ listingID }),
)

const handleRemoveSavedListing = (
  listings: ListingProperty[],
  listingID: number,
  isSavedProperties: boolean,
) => {
  if (isSavedProperties) {
    return listings.filter((listing) => listing.id !== listingID)
  }
  return listings.map((listing) =>
    listing.id === listingID ? { ...listing, has_user_saved_listing: false } : listing,
  )
}

export const listingPropertiesSlice = createSlice({
  name: 'listingProperties',
  initialState,
  reducers: {
    setQueryValues: (state, action) => ({
      ...state,
      queryValues: action.payload,
    }),
    setPriceSelected: (state, action) => ({
      ...state,
      priceSelected: action.payload,
    }),
    setPriceMinSelected: (state, action) => ({
      ...state,
      priceMinSelected: action.payload,
    }),
    setPriceMaxSelected: (state, action) => ({
      ...state,
      priceMaxSelected: action.payload,
    }),
    setDepositSelected: (state, action) => ({
      ...state,
      depositSelected: action.payload,
    }),
    setDepositMaxSelected: (state, action) => ({
      ...state,
      depositMaxSelected: action.payload,
    }),
    setBuildTypeSelected: (state, action) => ({
      ...state,
      buildTypeSelected: action.payload,
    }),
    setDevelopmentSelected: (state, action) => ({
      ...state,
      developmentSelected: action.payload,
    }),
    setLocationsSelected: (state, action) => ({
      ...state,
      locationsSelected: action.payload,
    }),
    setCompletionDateSelected: (state, action) => ({
      ...state,
      completionDateSelected: action.payload,
    }),
    setSortByValue: (state, action) => ({
      ...state,
      sortByValue: action.payload.value,
      sortByQuery: action.payload.query,
    }),
    unsetListingProperty: (state) => {
      sessionStorage.removeItem('listingID')

      return {
        ...state,
        listingProperty: null,
      }
    },
    unsetOtherListings: (state) => ({
      ...state,
      otherListings: { isLoading: false, total: 0, list: [] },
    }),
    loadMoreListings: (state) => ({
      ...state,
      size: state.size + 12,
    }),

    updateListingProgress: (state, action) => ({
      ...state,
      listingProgress: action.payload,
    }),
    setListingsToInitialState: () => initialState,
    setPropertyListingSize: (state, action) => {
      return {
        ...state,
        size: action.payload.size,
      }
    },
    setUserSelectedProperties: (state, action) => ({
      ...state,
      userSelectedProperties: action.payload,
    }),
    setIsSelectedPropertiesModalOpen: (state, action) => ({
      ...state,
      isSelectedPropertiesModalOpen: action.payload,
    }),
    setPropertiesListViewMode: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    clearInvestmentsCalculation: (state) => ({
      ...state,
      investmentsCalculation: initialState.investmentsCalculation,
    }),
    setInvestmentsCalculationParams: (state, action) => ({
      ...state,
      investmentsCalculationParams: action.payload,
    }),
    clearInvestmentsCalculationParams: (state) => ({
      ...state,
      investmentsCalculationParams: initialState.investmentsCalculationParams,
    }),
    setBrowsePropertyCurrentListingSelected: (state, action) => ({
      ...state,
      browsePropertyCurrentListingSelectedID: action.payload,
    }),
    clearBrowsePropertyCurrentListingSelected: (state) => ({
      ...state,
      browsePropertyCurrentListingSelectedID: null,
    }),
    setListingAreaHighlightSelected: (state, action) => ({
      ...state,
      listingAreaHighlightSelected: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getAllListings.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(getAllListings.rejected, (state) => ({
      ...state,
      isLoading: false,
    }))
    builder.addCase(getAllListings.fulfilled, (state, action) => ({
      ...state,
      listings: action.payload.data,
      isLoading: false,
      totalListings: action.payload.total,
      totalSavedListings: action.payload.count_saved,
    }))
    builder.addCase(getFilteredListings.rejected, (state) => ({
      ...state,
      isLoading: false,
    }))
    builder.addCase(getFilteredListings.fulfilled, (state, action) => ({
      ...state,
      listings: action.payload.data,
      isLoading: false,
      totalListings: action.payload.total,
      totalSavedListings: action.payload.count_saved,
    }))
    builder.addCase(getListingsFilters.fulfilled, (state, action) => ({
      ...state,
      filters: action.payload,
    }))
    builder.addCase(getSinglePropertyListing.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(getSinglePropertyListing.fulfilled, (state, action) => {
      return {
        ...state,
        listingProperty: action.payload,
        isLoading: false,
      }
    })
    builder.addCase(getOtherPropertyListings.fulfilled, (state, action) => {
      const { listings, total } = action.payload
      return {
        ...state,
        otherListings: {
          list: listings,
          total,
          isLoading: false,
        },
      }
    })
    builder.addCase(getAllUserListings.fulfilled, (state, action) => ({
      ...state,
      allUserListings: action.payload.listings,
    }))
    builder.addCase(saveListing.fulfilled, (state, action) => {
      const { isSingleListing } = action.meta.arg
      const newSavedCount = state.totalSavedListings + 1
      if (isSingleListing) {
        return {
          ...state,
          totalSavedListings: newSavedCount,
          listingProperty: {
            ...state.listingProperty,
            has_user_saved_listing: true,
          },
        }
      }
      return {
        ...state,
        totalSavedListings: newSavedCount,
        listings: state.listings.map((listing) =>
          listing.id === action.meta.arg.listingID
            ? { ...listing, has_user_saved_listing: true }
            : listing,
        ),
      }
    })
    builder.addCase(removeSavedListing.fulfilled, (state, action) => {
      const { isSingleListing } = action.meta.arg
      const newSavedCount = state.totalSavedListings - 1

      if (isSingleListing) {
        return {
          ...state,
          totalSavedListings: newSavedCount,
          listingProperty: {
            ...state.listingProperty,
            has_user_saved_listing: false,
          },
        }
      }
      return {
        ...state,
        totalSavedListings: newSavedCount,
        listings: handleRemoveSavedListing(
          state.listings,
          action.meta.arg.listingID,
          action.meta.arg.isSavedProperties,
        ),
      }
    })
    builder.addCase(getListingInvestmentsCalculation.fulfilled, (state, action) => {
      const {
        income: { gross_yield_percentage },
        property_analysis: {
          irr,
          return_on_investment_percentage,
          total_investment_in_cents,
          first_year_rental_income_pre_tax_in_cents,
        },
      } = action.payload

      return {
        ...state,
        investmentsCalculation: {
          total_return_in_cents:
            new Big(total_investment_in_cents * return_on_investment_percentage).toNumber() +
            total_investment_in_cents,
          total_investment_in_cents,
          annual_return_percentage: irr,
          gross_yield_percentage,
          first_year_rental_income_pre_tax_in_cents,
        },
      }
    })
    builder.addCase(getListingAreaHighlights.rejected, (state) => ({
      ...state,
      areaHighlights: [],
      listingAreaHighlightSelected: null,
    }))
    builder.addCase(getListingAreaHighlights.fulfilled, (state, action) => {
      if (action.payload?.length) {
        return {
          ...state,
          areaHighlights: action.payload,
          listingAreaHighlightSelected: action.payload[0],
        }
      }

      return { ...state }
    })
  },
})

export const {
  setQueryValues,
  setPriceSelected,
  setPriceMinSelected,
  setPriceMaxSelected,
  setDepositSelected,
  setDepositMaxSelected,
  setLocationsSelected,
  setBuildTypeSelected,
  setDevelopmentSelected,
  setCompletionDateSelected,
  setSortByValue,
  unsetListingProperty,
  unsetOtherListings,
  loadMoreListings,
  updateListingProgress,
  setListingsToInitialState,
  setPropertyListingSize,
  setUserSelectedProperties,
  setIsSelectedPropertiesModalOpen,
  setPropertiesListViewMode,
  clearInvestmentsCalculation,
  setInvestmentsCalculationParams,
  clearInvestmentsCalculationParams,
  setBrowsePropertyCurrentListingSelected,
  clearBrowsePropertyCurrentListingSelected,
  setListingAreaHighlightSelected,
} = listingPropertiesSlice.actions

export default listingPropertiesSlice.reducer
