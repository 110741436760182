import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import { enableActivityTracking, trackPageView } from '@snowplow/browser-tracker'
import { useCallback, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useDispatch } from 'react-redux'
import { install } from 'resize-observer'

import { NON_AUTH_ROUTES } from 'src/config/constants'
import { useTypedSelector } from 'src/state/reducers'
import { AUTH_STORAGE_KEY, decodeAuthStorage } from 'src/utils/auth'

import { getNearestCompletionDate } from 'src/utils/functions'
import { useSaveParams } from 'src/hooks/useSaveParams'
import useLaunchDarklyIdentify from 'src/hooks/useLaunchDarklyIdentify'
import { fetchTrackingParameters } from 'src/utils/utmParams'

function useAppWrapper() {
  const dispatch = useDispatch()
  const user = useTypedSelector((state) => state.user)
  const { pathname } = useLocation()

  const clientWindow = typeof window !== 'undefined' && window
  const userID = clientWindow?.sessionStorage?.getItem('userID')
  const userEmail = clientWindow?.sessionStorage?.getItem('user_email')
  const ldIdentify = useLaunchDarklyIdentify()
  const { investments } = useTypedSelector((state) => state.investments) || []
  const propertyPurchase = useTypedSelector((state) => state.propertyPurchase)

  if (import.meta.env.MODE === 'production') {
    TagManager.initialize({ gtmId: 'GTM-M27RF2P' })
  }
  install() // polyfill for ResizeObserver (https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver)

  // HubSpot tracking integration
  // eslint-disable-next-line no-underscore-dangle, no-multi-assign
  const _hsq = (window._hsq = window._hsq || [])
  _hsq.push(['setPath', '/'])

  useEffect(() => {
    if (userID) {
      const country = window?.sessionStorage?.getItem('userCountry') || 'unknown'

      if (
        (import.meta.env.MODE === 'preview' || import.meta.env.MODE === 'production') &&
        window?.Appcues
      ) {
        const incorporatedCompanies = window?.localStorage?.getItem('hasIncorporatedCompanies')
        const zeroBalance = window?.sessionStorage?.getItem('zero_balance')
        const balanceAbove25k = window?.sessionStorage?.getItem('balance_above_25')
        const completedProperty = window?.sessionStorage?.getItem('completed_ltd_property')

        if (!incorporatedCompanies && investments.length > 0) {
          // Check if user is shareholder of a company incorporated before November 2 of 2023
          const hasIncorporatedCompanies = investments.some((investiment) => {
            const dateOfIncorporation = investiment.company?.date_of_incorporation
            if (dateOfIncorporation) {
              const formatedDateOfIncorporation = new Date(dateOfIncorporation).getTime()
              const docsDate = new Date('2023-11-02').getTime()
              return formatedDateOfIncorporation < docsDate
            }
            return false
          })
          window?.localStorage?.setItem(
            'hasIncorporatedCompanies',
            hasIncorporatedCompanies ? 'Yes' : 'No',
          )
        }

        if (!zeroBalance && investments.length > 0) {
          // Check if user has any zero balance investments
          const hasAnyZeroBalanceInvestment = investments.some((investiment) => {
            const balance = investiment.company?.wallet?.balance_in_cents
            return balance === 0
          })
          window?.sessionStorage?.setItem(
            'zero_balance',
            hasAnyZeroBalanceInvestment ? 'Yes' : 'No',
          )
        }

        if (!balanceAbove25k && investments.length > 0) {
          // Check if user has any +25k balance investments
          const hasAny25kBalanceInvestment = investments.some((investiment) => {
            const balance = investiment.company?.wallet?.balance_in_cents ?? 0
            return balance >= 2500000
          })
          window?.sessionStorage?.setItem(
            'balance_above_25',
            hasAny25kBalanceInvestment ? 'Yes' : 'No',
          )
        }

        if (!completedProperty && investments.length > 0) {
          // Check if user has any completed property
          const currentDate = dayjs()
          const hasCompletedProperty = investments.some((investiment) => {
            const completionDate = investiment.property?.purchase_details?.completion_date
            return completionDate && dayjs(completionDate).isBefore(currentDate)
          })
          window?.sessionStorage?.setItem(
            'completed_ltd_property',
            hasCompletedProperty ? 'Yes' : 'No',
          )
        }

        const completionDate = getNearestCompletionDate(
          propertyPurchase?.purchase_details?.completion_date,
          investments,
        )

        const queryParams = new URLSearchParams(window.location.search)

        // Fetch utm parameters from session storage and filter out
        // undefined ones.
        const utmParams: { [key: string]: string } = Object.fromEntries(
          Object.entries(fetchTrackingParameters() ?? {})
            .map(([key, value]) => (value ? [key, value] : null))
            .filter((entry) => !!entry) as [string, string][],
        )

        queryParams.forEach((value, key) => {
          if (key.startsWith('utm')) {
            utmParams[key] = value
          }
        })

        window.Appcues.page()
        window.Appcues.identify(
          import.meta.env.MODE === 'production' ? userID : `${import.meta.env.MODE}_${userID}`,
          {
            country,
            was_shareholder_before_docs:
              window?.localStorage?.getItem('hasIncorporatedCompanies') || 'No',
            zero_balance: window?.sessionStorage?.getItem('zero_balance') || 'No',
            completion_date: completionDate || '',
            balance_above_25: window?.sessionStorage?.getItem('balance_above_25') || 'No',
            completed_ltd_property:
              window?.sessionStorage?.getItem('completed_ltd_property') || 'No',
            ...utmParams,
          },
        )
        window.Appcues.track()
      }

      ldIdentify()

      // HubSpot tracking integration
      if (userEmail) {
        _hsq.push([
          'identify',
          {
            email: userEmail,
          },
        ])
      }
    }
    if (window) {
      NON_AUTH_ROUTES.some((route) => route === pathname)
        ? TagManager.dataLayer({
            dataLayer: {
              event: 'page_meta_data',
              page: window.location.pathname + window.location.search,
              user_id: null,
              user_type: 'NonLoggedIn', // typo from external company, please keep as it is
            },
          })
        : TagManager.dataLayer({
            dataLayer: {
              event: 'page_meta_data',
              page: window.location.pathname + window.location.search,
              user_id: userID || null,
              user_type: userID ? 'loggedIn' : 'NonLoggedIn', // typo from external company, please keep as it is
            },
          })
      // HubSpot tracking integration
      _hsq.push(['setPath', pathname])
      _hsq.push(['trackPageView'])
    }
  }, [userID, clientWindow, pathname])

  useEffect(() => {
    enableActivityTracking({
      minimumVisitLength: 30,
      heartbeatDelay: 10,
    })
    trackPageView()
  }, [pathname])

  // sync sign-in and sign-out across tabs
  const checkStorageAuthChange = useCallback(
    (oldValue: string | null, newValue: string | null) => {
      const oldAuth = decodeAuthStorage(oldValue)
      const newAuth = decodeAuthStorage(newValue)

      const signedOut = oldAuth && !newAuth
      const signedIn = !oldAuth?.decodedToken.authorised && newAuth?.decodedToken.authorised
      const changedUser =
        oldAuth && newAuth && oldAuth?.decodedToken.user_id !== newAuth?.decodedToken.user_id

      // sign-out
      if (signedOut) {
        window.location.pathname = '/'
      }

      // sign-in or token revalidation
      if (signedIn || changedUser) {
        if (NON_AUTH_ROUTES.includes(window.location.pathname)) {
          window.location.pathname = '/dashboard'
        } else {
          window.location.reload()
        }
      }
    },
    [dispatch, user],
  )

  // check session on storage and tab visibility changes
  useEffect(() => {
    const onAuthStorageChange = ({ key, oldValue, newValue }: StorageEvent) => {
      if (key === AUTH_STORAGE_KEY) {
        checkStorageAuthChange(oldValue, newValue)
      }
    }

    window.addEventListener('storage', onAuthStorageChange)
    return () => window.removeEventListener('storage', onAuthStorageChange)
  }, [checkStorageAuthChange])

  // Save gclid and utms if present, to pass later to BE when Enquiring
  useSaveParams()

  return null
}
export default useAppWrapper
