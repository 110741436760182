import { useForm } from 'react-hook-form'
import { Box, Typography, useTheme } from '@material-ui/core'
import { XClose } from '@gground/capcom.icons'
import { Textarea } from '@gground/capcom.form'
import { Button } from '@gground/capcom.core'

import { validateWhitespace } from 'src/utils/validation'
import { StyledContainer, FormContainer, StyledFormLabel } from './UserFeedbackModal.styles'
import useUserFeedbackModal from './useUserFeedbackModal'

const UserFeedbackModal = () => {
  const theme = useTheme()
  const { register, handleSubmit, errors } = useForm({ defaultValues: { notes: '' } })
  const { modalConfig, isSubmitting, onSubmit, onClose } = useUserFeedbackModal()

  if (!modalConfig?.open) return null

  return (
    <StyledContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center" gridGap={8}>
        <Typography variant="h3">
          {modalConfig.title ||
            (modalConfig.isFeedbackPositive ? 'That’s great to hear!' : 'Sorry to hear that!')}
        </Typography>
        <XClose
          size={20}
          stroke={theme.ggPalette.teal[500]}
          cursor="pointer"
          htmlColor={theme.ggPalette.teal[500]}
          data-testid="x-close-button"
          onClick={onClose}
        />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer>
          <div>
            <StyledFormLabel>
              {modalConfig.questionLabel ||
                (modalConfig.isFeedbackPositive
                  ? 'What have you found useful? What would you like to see next?'
                  : 'What is the issue you were facing? What can we do to make it better?')}
            </StyledFormLabel>
            <Textarea
              ref={register({
                required: 'This field is required',
                maxLength: {
                  value: 500,
                  message: 'Maximum of 500 characters',
                },
                validate: {
                  validateWhitespace,
                },
              })}
              id="notes"
              name="notes"
              fullWidth
              rows={4}
              inputProps={{ placeHolder: 'Type your feedback here...', maxLength: 500 }}
              error={!!errors.notes}
              helperText={errors.notes?.message}
            />
          </div>

          <Box display="flex" justifyContent={{ xs: 'center', mobile: 'start' }}>
            <Button
              id="submit-feedback"
              type="submit"
              mobileFullWidth={false}
              isLoading={isSubmitting}
            >
              Submit
            </Button>
          </Box>
        </FormContainer>
      </form>
    </StyledContainer>
  )
}

export default UserFeedbackModal
