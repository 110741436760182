export const mockFulfilledRequest = (delayInSeconds = 0): Promise<any> =>
  new Promise((resolve) => setTimeout(resolve, delayInSeconds * 1000))

export const mockRejectedRequest = (delayInSeconds = 0): Promise<any> =>
  new Promise((_, reject) => setTimeout(reject, delayInSeconds * 1000))

export const formatParams = (params: any, appendParams = false) => {
  const formatted = params
    ? Object.entries(params)
        .map(([key, value]) => [key, Array.isArray(value) ? `${value.join(`&${key}=`)}` : value])
        .filter((param) => param[1])
    : []
  return formatted.length
    ? `${appendParams ? '&' : '?'}${formatted.map((param) => `${param[0]}=${param[1]}`).join('&')}`
    : ''
}
