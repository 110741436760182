import { styled, Typography } from '@material-ui/core'

export const StyledContainer = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'sticky',
  right: theme.spacing(0),
  bottom: theme.spacing(8),
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.ggPalette.obsidian[300]}`,
  background: theme.ggPalette.special.white,
  boxShadow: '0px 0px 8px 0px #385B640D',
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  color: theme.ggPalette.obsidian[900],
  boxSizing: 'border-box',
  zIndex: theme.zIndex.modal,
  [theme.breakpoints.up('mobile')]: {
    position: 'fixed',
    width: '550px',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    gap: theme.spacing(3),
  },
}))

export const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}))

export const StyledFormLabel = styled(Typography)(({ theme }) => ({
  color: theme.ggPalette.obsidian[900],
  marginBottom: theme.spacing(1.25),
}))
