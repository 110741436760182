import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { newTracker, trackSelfDescribingEvent } from '@snowplow/browser-tracker'
import { GaCookiesPlugin } from '@snowplow/browser-plugin-ga-cookies'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import TagManager from 'react-gtm-module'
import * as api from 'src/api/ggAPI'

import User from 'src/types/User'
import ActivePackage from 'src/types/SingleActivePackage'
import {
  CompanySubscription,
  SubscriptionUpdateRequest,
  SubscriptionUpdate,
} from 'src/types/StandingOrderUpdate'
import { clearAuth, getAuth } from 'src/utils/auth'
import type { RootState } from '../reducers'
import { showLoader } from './loader.slice'
import { deleteCurrentPPfromLocalStorage } from 'src/utils/functions'

dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(customParseFormat)

newTracker('sp1', `${import.meta.env.VITE_SNOWPLOW_COLLECTOR}`, {
  appId: 'customer-app',
  postPath: '/com.getground/track',
  discoverRootDomain: true,
  stateStorageStrategy: 'cookieAndLocalStorage',
  cookieSameSite: 'Lax', // Recommended
  plugins: [GaCookiesPlugin()],
})

export const updateUser = createAsyncThunk(
  'user/update',
  (
    {
      userID,
      data,
      requestSource,
    }: { userID: number; data: Partial<User>; requestSource?: string },
    { rejectWithValue },
  ) => {
    return api.updateUser({ userID, data, requestSource }).catch((error) => {
      if (error?.response?.data?.field === 'email') {
        return rejectWithValue(error?.response?.data?.field)
      }
      throw error
    })
  },
)

export const getUser = createAsyncThunk('user/getUser', api.getUser)

export const completeOnboarding = createAsyncThunk(
  'user/completeOnboarding',
  ({ skipKyc }, { getState }) => {
    const userID = getState().user.id
    return api.completeOnboarding(userID, skipKyc ? { skip_kyc: true } : {})
  },
)

export const verifyEmail = createAsyncThunk('user/verifyEmail', ({ verificationCode }) =>
  api.verifyEmail(verificationCode),
)

export const getCCTermsConsent = createAsyncThunk('user/getCCTermsAgreement', (_, { getState }) => {
  const userID = getState().user.id
  return api.getCCTermsAgreement(userID, 'CURRENCYCLOUD')
})

export const postCCTermsConsent = createAsyncThunk(
  'user/postCCTermsConsent',
  ({ ppID }, { getState }) => {
    const userID = getState().user.id
    return api.postCCTermsAgreement(userID, 'CURRENCYCLOUD', ppID)
  },
)

export const shouldUserDoNPS = createAsyncThunk(
  'user/should-user-do-NPS',
  ({ type, investmentID }: { type: string; investmentID?: number }) => api.shouldUserDoNPS(type),
)

export interface TrackSnowplow {
  category: string
  action: string
  label?: string
  property?: string
  propertyID?: number
  companyID?: number
  /**
   * The field to pass extra properties as a string, if object is passed, it will be stringified
   * If fields are new and never used previously for `extra`, then update those to analytics engineer
   */
  extra?: string | Record<string, unknown>
}

export const trackSnowplow = createAsyncThunk(
  'user/trackSnowplow',
  (
    { category, action, label, property, propertyID, companyID, extra }: TrackSnowplow,
    { getState },
  ) => {
    const userID = getState().user?.id?.toString() || null
    const PPID =
      propertyID?.toString() ||
      getState().propertyPurchase?.id?.toString() ||
      getState().listingProperty?.listingProperty?.id?.toString() ||
      null
    const _companyID = companyID?.toString() || getState().company?.id?.toString() || PPID
    const timestamp = dayjs.utc().format('YYYY-MM-DDTHH:mm:ss')

    return trackSelfDescribingEvent({
      event: {
        schema: 'iglu:com.getground/default/jsonschema/1-1-0',
        data: {
          category,
          action,
          label,
          // uid, // deprecated
          user_id: userID,
          pp_id: PPID,
          company_id: _companyID,
          timestamp,
          property: property || null,
          extra: typeof extra === 'string' ? extra : JSON.stringify(extra),
        },
      },
    })
  },
)

interface DataLayer {
  event: string
  eventAction: string
}

export const sendDataLayer = createAsyncThunk(
  'user/sendDataLayer',
  ({ event, eventAction }: DataLayer, { getState }) => {
    TagManager.dataLayer({
      dataLayer: {
        event,
        event_action: eventAction,
        user_id: getState().user?.id?.toString() || null,
      },
    })
  },
)

interface DataLayerPurChase {
  txID: string | number
  tax: number
  value: number
  propertyID: number
  companyStructure: string
}

export const sendDataLayerPurchase = createAsyncThunk(
  'user/sendDataLayerPurchase',
  ({ txID, tax, value, propertyID, companyStructure }: DataLayerPurChase, { getState }) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'purchase',
        ecommerce: {
          transaction_id: txID,
          tax,
          value,
          user_id: getState().user?.id?.toString() || null,
          items: [
            {
              item_id: propertyID,
              item_name: companyStructure,
              quantity: 1,
              price: value,
              item_category: companyStructure,
            },
          ],
        },
      },
    })
  },
)

export const getUserPromos = createAsyncThunk('user/getPromos', api.getUserPromos)

export const unAuthJwt = createAsyncThunk(
  'user/unAuthJWT',
  ({ email, password, rememberDeviceToken }, { dispatch }) => {
    const response = api.unAuthJwt({ email, password, rememberDeviceToken })
    return response
      .then((res) => {
        return res
      })
      .catch((error) => {
        let message = ''
        if (error.status === 429) {
          if (error.response.data.details.unlock_timestamp) {
            const now = dayjs()
            const unlockTime = dayjs(error.response.data.details.unlock_timestamp)
            const timeDiff = dayjs.duration(unlockTime.diff(now))
            const unlockInTime = timeDiff.hours()
              ? timeDiff.format('HH[h]mm[m]ss[s]')
              : timeDiff.format('mm[m]ss[s]')
            message = `You have requested too many 2FA codes without successfully performing 2FA and this is now locked. You can try again in ${unlockInTime}`
          } else if (error.message === 'Rate limit has been reach') {
            message = 'You have made too many requests this is now locked. You can try again later.'
          } else {
            message = error.message
          }
        } else {
          switch (error.message) {
            case '469':
              message =
                'This email has not been verified. Please check your email and verify your email address.'
              break
            case 'Invalid password':
            case 'Unable to authenticate request.':
            case '401':
              message = 'Incorrect e-mail/password combination. Try again.'
              break
            default:
              message =
                'There is an issue with your network. Please access GetGround from a different network.'
              break
          }
        }
        dispatch(setUserError(message))
        throw error
      })
  },
)

export const authLoginWithGoogle = createAsyncThunk(
  'user/authLoginWithGoogle',
  ({ googleToken, rememberDeviceToken }, { dispatch }) => {
    return api
      .loginWithGoogle({ google_token: googleToken, remember_device_token: rememberDeviceToken })
      .then((response) => response)
      .catch((error) => {
        if (error.status === 429) {
          const now = dayjs()
          const unlockTime = dayjs(error.response.data.unlock_timestamp)
          const timeDiff = dayjs.duration(unlockTime.diff(now))
          const unlockInTime = timeDiff.hours()
            ? timeDiff.format('HH[h]mm[m]ss[s]')
            : timeDiff.format('mm[m]ss[s]')

          dispatch(
            setUserError(
              `You have requested too many 2FA codes without successfully performing 2FA and this is now locked. You can try again in ${unlockInTime}`,
            ),
          )
        }
        if (error.status === 401) {
          dispatch(setSSOError('Before you login, you will need to sign up to GetGround.'))
        }
        if (error.status === 500) {
          dispatch(setUserError('There is an issue with our servers. Please try again later.'))
        }
        throw error
      })
  },
)

export const logout = createAsyncThunk('user/logout', () => {
  const auth = getAuth()
  if (auth) {
    clearAuth()
    deleteCurrentPPfromLocalStorage()
    sessionStorage.removeItem('propertyDetailsDraft')
    sessionStorage.removeItem('propertyAgentDraft')
    return api.logout(auth.token)
  }
})

interface SignUpWithGoogleArgs {
  googleToken: string
  referralCode?: string
  webSessionToken?: string
  utm?: {
    utm_source?: string
    utm_medium?: string
    utm_campaign?: string
  }
}

interface SignUpWithGoogleResponse {
  data: {
    token: string
    user: User
  }
}

export const authSignUpWithGoogle = createAsyncThunk<
  SignUpWithGoogleResponse,
  SignUpWithGoogleArgs
>(
  'user/authSignUpWithGoogle',
  ({ googleToken, referralCode, webSessionToken, utm }, { dispatch }) => {
    return api
      .signUpWithGoogle(googleToken, referralCode, webSessionToken, {
        utm_source: utm?.utm_source,
        utm_medium: utm?.utm_medium,
        utm_campaign: utm?.utm_campaign,
      })
      .then((response) => response)
      .catch((error) => {
        const { status } = error
        if (status === 409) {
          dispatch(showLoader())
          dispatch(authLoginWithGoogle({ googleToken }))
        }
        if (status === 500) {
          dispatch(setUserError('There is an issue with our servers. Please try again later.'))
        }
        if (status === 403) {
          dispatch(
            setUserError(
              'This email is not approved to register using this referral link. Please contact your referrer to get your email approved.',
            ),
          )
        }
        throw error
      })
  },
)

export const getPartnerInfo = createAsyncThunk(
  'user/getPartnerInfo',
  ({ referralCode, signUpCode }) => {
    if (referralCode) {
      return api.getReferralCodeInfo(referralCode)
    }
    if (signUpCode) {
      return api.getSignUpCodeInfo(signUpCode)
    }
  },
)

export const getRequestedDocuments = createAsyncThunk(
  'user/getRequestedDocuments',
  api.getRequestedDocuments,
)

export const updateRequestedDocuments = createAsyncThunk(
  'user/updateRequestedDocuments',
  async ({ requestID, request }, { rejectWithValue }) => {
    try {
      return await api.updateRequestedDocuments({ requestID, request })
    } catch ({
      response: {
        data: { error_code },
        status,
      },
    }) {
      return rejectWithValue({ code: status, message: error_code })
    }
  },
)

export const confirmSubscriptionUpdate = createAsyncThunk(
  'company/confirmSubscriptionUpdate',
  (data: SubscriptionUpdateRequest, { rejectWithValue }) =>
    api
      .confirmSubscriptionUpdate(data)
      .catch(({ status, response }) => rejectWithValue({ code: status, message: response?.data })),
)

export const setNewPassword = createAsyncThunk(
  'user/setNewPassword',
  ({ password, verificationCode }: { password: string; verificationCode: string }) =>
    api.setNewPassword(password, verificationCode),
)

interface UserState extends User {
  qrToken: any
  firstTimeSignature: boolean
  companiesRequiringConsent: any[]
  invitedShareholder: boolean
  promos: any[]
  error: any
  errorSSO: any
  partner: any
  is_rent_tracker_unlocked: boolean
  requestedDocuments: any
  products: ActivePackage[]
  search_products: ActivePackage[]
  subscription_changes_confirmation?: {
    companies_with_mismatch_standing_order_subscription: CompanySubscription[]
    subscription_changes: SubscriptionUpdate
  }
  is_external_consultant: boolean
  is_internal_consultant: boolean
  noOfVisitedProperties: number
  isUserUpdating: boolean
  disable_notifications: boolean
}

export const initialState: UserState = {
  id: null,
  qrToken: '',
  firstTimeSignature: false,
  companiesRequiringConsent: [],
  invitedShareholder: false,
  promos: [],
  first_name: '',
  middle_name: '',
  last_name: '',
  error: '',
  errorSSO: '',
  partner: null,
  is_rent_tracker_unlocked: false,
  is_external_consultant: false,
  is_internal_consultant: false,
  email: '',
  premise: '',
  city: '',
  street: '',
  posttown: '',
  postcode: '',
  thoroughfare: '',
  date_of_birth: null,
  nationality: null,
  passport_number: null,
  occupation: null,
  country: null,
  phone_number: null,
  birth_town: null,
  first_time_landlord: null,
  is_paying: null,
  first_time_buyer: null,
  global_properties_count: null,
  uk_properties_count: null,
  employment_status: null,
  employment_status_other_reason: null,
  has_completed_onboarding: null,
  two_factor_auth_method: null,
  requestedDocuments: {
    loading: true,
    requests: [],
    pending_properties: [],
  },
  products: [],
  search_products: [],
  noOfVisitedProperties: 0,
  isUserUpdating: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLogin: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    saveQR: (state, action) => {
      state.qrToken = action.payload
    },
    userUpdate: (state, action) => {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }
    },
    isUserFirstSignatureSetup: (state, action) => {
      state.firstTimeSignature = action.payload
    },
    isInvitedShareholder: (state) => {
      state.invitedShareholder = true
    },
    setSSOError: (state, action) => {
      state.errorSSO = action.payload
    },
    setUserError: (state, action) => {
      state.error = action.payload
    },
    addNoOfVisitedProperties: (state) => {
      state.noOfVisitedProperties = state.noOfVisitedProperties + 1
    },
    resetNoOfVisitedProperties: (state) => {
      state.noOfVisitedProperties = 0
    },
  },
  extraReducers: {
    [logout.pending.type]: () => initialState,
    [updateUser.pending.type]: (state, action) => ({
      ...state,
      ...action.payload,
      isUserUpdating: true,
    }),
    [updateUser.fulfilled.type]: (state, action) => ({
      ...state,
      ...action.payload,
      co_branding: state?.co_branding,
      isUserUpdating: false,
    }),
    [updateUser.rejected.type]: (state, action) => ({
      ...state,
      ...action.payload,
      isUserUpdating: false,
    }),

    [getUser.fulfilled.type]: (state, action) => ({
      ...state,
      ...action.payload,
      has_seen_get_property: action.payload?.has_seen_get_property || state.has_seen_get_property,
    }),
    [getCCTermsConsent.fulfilled.type]: (state, action) => ({
      ...state,
      companiesRequiringConsent: action.payload.non_consented_companies,
    }),
    [getUserPromos.fulfilled.type]: (state, action) => ({
      ...state,
      promos: action.payload,
    }),
    [verifyEmail.fulfilled.type]: (state, action) => ({
      ...state,
      id: action.payload.user_id,
      qrToken: action.payload.key_uri,
      ...action.payload,
    }),
    [getRequestedDocuments.fulfilled.type]: (state, action) => ({
      ...state,
      requestedDocuments: { ...action.payload, loading: false },
    }),
    [updateRequestedDocuments.fulfilled.type]: (state, action) => ({
      ...state,
      requestedDocuments: { ...action.payload },
    }),
    [getPartnerInfo.fulfilled.type]: (state, action) => ({
      ...state,
      partner: {
        co_branding: {
          ...action.payload.co_branding,
          partner_name: action.payload.name,
          partner_type: action.payload.type,
          logo_url: action.payload.logo_url,
        },
        lead_consultant_email: action.payload.lead_consultant_email,
      },
    }),
    [confirmSubscriptionUpdate.fulfilled.type]: (state) => ({
      ...state,
      subscription_changes_confirmation: undefined,
    }),
  },
})

export const {
  userLogin,
  saveQR,
  userUpdate,
  isUserFirstSignatureSetup,
  isInvitedShareholder,
  setSSOError,
  setUserError,
  addNoOfVisitedProperties,
  resetNoOfVisitedProperties,
} = userSlice.actions

export const selectUser = (state: RootState) => state.user as UserState

export default userSlice.reducer
