import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as api from 'src/api/ggAPI'

import { MortgagesState } from '../types/Mortgages'

const stateInSessionStorage =
  typeof window !== 'undefined' ? sessionStorage.getItem('mortgageData') : null

const defaultMortgageState = {
  company_id: null,
  requested_amount_in_cents: null,
  repayment_type: null,
  personal_guarantee: null,
  needs_other_mortgages: null,
  annual_income_in_cents: null,
  uk_properties_count: null,
  uk_mortgaged_properties_count: null,
  mortgage_term_years: null,
  uk_properties_value_in_cents: null,
  initial_fixed_period: null,
  address_history: {
    current: {
      address_id: null,
      moved_in_month: null,
      moved_in_year: null,
    },
    others: null,
  },
  resides_in_uk: null,
  years_resides_in_uk: null,
  right_to_reside_uk: null,
  residency_status_details: null,
  bankrupt_or_iva: null,
  bankrupt_or_iva_details: null,
  missed_loan_payments: null,
  missed_loan_payments_reason: null,
  credit_defaults_or_ccj: null,
  credit_defaults_or_ccj_reason: null,
  has_confirment: null,
  other_outstanding_mortgage_balance_in_cents: null,
  monthly_mortgage_payments_in_cents: null,
  monthly_rental_income_in_cents: null,
  global_properties_count: null,
  mortgage_purpose: null,
}

export const initialState: MortgagesState = stateInSessionStorage
  ? JSON.parse(stateInSessionStorage)
  : defaultMortgageState

const mortgagesSlice = createSlice({
  name: 'mortgages',
  initialState,
  reducers: {
    mortgageUpdate: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    clearMortgageState: () => {
      sessionStorage.removeItem('mortgageData')
      sessionStorage.removeItem('mortgageStep')
      sessionStorage.removeItem('lastStepCompleted')
      return defaultMortgageState
    },
  },
})

export const { mortgageUpdate, clearMortgageState } = mortgagesSlice.actions

export default mortgagesSlice.reducer
