import { useMutation } from '@tanstack/react-query'
import {
  type RegisterInterestRequest,
  type UpdateUser,
  dismissFeedback,
  registerInterest,
  requestFileUrls,
  resendSMS,
  sendFeedback,
  updateUser,
} from './routes'
import { useTypedDispatch, useTypedSelector } from 'src/state/reducers'
import { FeedBack } from 'src/types/Feedback'
import { getUser } from 'src/state/slices/user.slice'
import { queryClient } from 'src/utils/reactQueryConfig'
import { userKeys } from './queries'

export const useSendSMS = () =>
  useMutation({
    mutationFn: () => resendSMS(),
  })

export const useRegisterInterest = () => {
  const userID = useTypedSelector((state) => state.user.id)

  return useMutation({
    mutationFn: ({ interest, isInterested, comments }: Omit<RegisterInterestRequest, 'userID'>) =>
      registerInterest({ userID, interest, isInterested, comments }),
  })
}

export const useRequestFileUrls = () => {
  return useMutation({
    mutationFn: requestFileUrls,
  })
}

export const useSendUserFeedback = () =>
  useMutation({
    mutationFn: (data: FeedBack) => sendFeedback(data),
  })

export const useDismissUserFeedback = () =>
  useMutation({
    mutationFn: (key: string) => dismissFeedback({ key }),
    onSuccess: (_, arg) => {
      queryClient.invalidateQueries(userKeys.user_feedback([arg]))
    },
  })
export const useUpdateUser = () => {
  const dispatch = useTypedDispatch()
  const userId = useTypedSelector((state) => state.user?.id)

  return useMutation({
    mutationFn: ({ disable_notifications }: Pick<UpdateUser, 'disable_notifications'>) =>
      updateUser({ userId, disable_notifications }),
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.user, { refetchType: 'all' })
      // old data
      dispatch(getUser())
    },
  })
}
