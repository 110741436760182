import { formatCurrencyWithComma, penniesToPounds } from '@gground/capcom.utils'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Big from 'big.js'

import {
  getPropertyReports as apiGetPropertyReports,
  getPropertyReportAnalysis as apiGetPropertyReportAnalysis,
  getSinglePropertyReport as apiGetSinglePropertyReport,
  getAreaGuides as apiGetAreaGuides,
  createReportSelfServe as apiCreateReportSelfServe,
  deletePropertyReport as apiDeletePropertyReport,
} from 'src/api/ggAPI'
import {
  PropertyAnalysisItem,
  PropertyReportsListItem,
  SinglePropertyReport,
} from 'src/types/PropertyReports'
import { GG_SEARCH_FEE, MINIMUM_GG_SEARCH_FEE } from 'src/config/constants'

export const getUserPropertyReports = createAsyncThunk(
  'propertyReports/getUserPropertyReports',
  apiGetPropertyReports,
)

export const getSinglePropertyReport = createAsyncThunk(
  'propertyReports/getSinglePropertyReport',
  ({ reportID }: { reportID: string; state: any }) => {
    return apiGetSinglePropertyReport({ reportID: Number(reportID) })
  },
)

export const getSinglePropertyReportAnalysis = createAsyncThunk(
  'propertyReports/getSinglePropertyReportAnalysis',
  apiGetPropertyReportAnalysis,
)

export const deletePropertyReport = createAsyncThunk(
  'propertyReports/deletePropertyReport',
  apiDeletePropertyReport,
)

export const getAreaGuides = createAsyncThunk('propertyReports/getAreaGuides', apiGetAreaGuides)

export const createReportSelfServe = createAsyncThunk(
  'listingProperties/createReportSelfServe',
  apiCreateReportSelfServe,
)

export interface PropertyReportsState {
  list: PropertyReportsListItem[]
  singlePropertyReport: SinglePropertyReport
  reportAnalysis: []
  allAreaGuides: { key: string; label: string }[]
}

export const initialState: PropertyReportsState = {
  list: [],
  singlePropertyReport: {
    resources: [],
    budget: 0,
    created_at: '',
    preferredAreas: '',
    areaInformationResources: [],
    propertyAnalysis: {
      columns: [],
      rows: [],
    },
    listing_options: [],
    payments: {
      columns: [],
      rows: [],
    },
    income: {
      columns: [],
      rows: [],
    },
  },
  reportAnalysis: [],
  allAreaGuides: [],
}

const propertyReportSlice = createSlice({
  name: 'propertyReports',
  initialState,
  reducers: {
    clearPropertyReportData: () => initialState,
    clearSinglePropertyReport: (state) => ({
      ...state,
      singlePropertyReport: initialState.singlePropertyReport,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getUserPropertyReports.fulfilled, (state, action) => {
      state.list = action.payload
    })

    builder.addCase(getAreaGuides.fulfilled, (state, action) => ({
      ...state,
      allAreaGuides: action.payload,
    }))

    builder.addCase(getSinglePropertyReport.fulfilled, (state, action) => {
      const {
        resources,
        budget_in_cents,
        preferred_areas,
        listing_options,
        area_information_resources,
        created_at,
        starting_ltv_percentage_in_bps,
        starting_interest_rate_percentage_in_bps,
        consultant,
        is_created_by_user,
        external_options,
      } = action.payload
      const options = listing_options?.map((option, index) => ({
        ...option,
        title: `Option ${index + 1}`,
        post_town: option.posttown,
        shortened_post_code: option.shortened_postcode,
      }))
      const externalOptions = external_options?.map((option, index) => ({
        ...option,
        title: `Option ${index + 1}`,
        post_town: option.posttown,
        shortened_post_code: option.shortened_postcode,
      }))
      state.singlePropertyReport = {
        ...state.singlePropertyReport,
        isCreatedByUser: is_created_by_user,
        resources,
        created_at,
        budget: penniesToPounds(budget_in_cents || 0),
        preferredAreas: preferred_areas,
        areaInformationResources: area_information_resources,
        listing_options: options,
        starting_ltv_percentage: Big(starting_ltv_percentage_in_bps || 0)
          .div(100)
          .toNumber(),
        starting_interest_rate_percentage: new Big(starting_interest_rate_percentage_in_bps || 0)
          .div(100)
          .toNumber(),
        consultant,
        external_options: externalOptions,
      }
    })
    builder.addCase(getSinglePropertyReportAnalysis.fulfilled, (state, action) => {
      const columns = action.payload.map((item: { shortened_address: string }, idx: number) => ({
        label: `Option ${idx + 1}`,
        address: item.shortened_address,
      }))

      const optionsSummary = action.payload.map((item) => {
        const {
          total_investment_in_cents = 0,
          return_on_investment_percentage = 0,
          total_return_in_cents = 0,
          is_highest_monthly_income = false,
          is_highest_rental_yield = false,
          is_highest_roi = false,
          is_lowest_investment = false,
        } = item?.property_analysis || {}
        const { gross_yield_percentage = 0, monthly_net_income_post_tax_in_cents = 0 } =
          item?.income || {}

        return {
          total_investment_in_cents,
          rental_yield_percentage: gross_yield_percentage,
          monthly_net_income_post_tax_in_cents,
          return_on_investment_percentage,
          total_return_in_cents,
          is_highest_monthly_income,
          is_highest_rental_yield,
          is_highest_roi,
          is_lowest_investment,
        }
      })

      const formatCurrency = (valueInCents: number) => {
        return `£${
          valueInCents && formatCurrencyWithComma(Big(valueInCents).div(100).toFixed(0), 0, 0)
        }`
      }

      state.singlePropertyReport = {
        ...state.singlePropertyReport,
        optionsSummary,
        propertyAnalysis: {
          columns: [{ label: '' }, ...columns],
          rows: [
            {
              name: 'Invested',
              tooltip: 'The initial cost of your investment.',
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.property_analysis.total_investment_in_cents),
              ),
            },
            {
              name: 'Net capital gain',
              tooltip:
                'Expected growth in the value of the development over your chosen length of investment.',
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.property_analysis.net_capital_gain_in_cents),
              ),
            },
            {
              name: 'Net rental income',
              tooltip: `Your rental income before tax, expenses, etc.`,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.property_analysis.net_rental_income_in_cents),
              ),
            },
            {
              name: 'Return on investment',
              tooltip:
                'Your returns over the length of your investment, as a percentage of your initial costs.',
              values: action.payload.map(
                (item: PropertyAnalysisItem) =>
                  `${Big(item.property_analysis.return_on_investment_percentage * 100).toFixed(
                    2,
                  )}%`,
              ),
            },
            {
              name: 'Annual return',
              tooltip:
                'The annual rate at which the value of your investment will grow over the period of your investment, taking into account both rental income and capital appreciation.',
              values: action.payload.map((item: PropertyAnalysisItem) =>
                item.property_analysis.irr
                  ? `${Big(item.property_analysis.irr * 100).toFixed(2)}%`
                  : 'N/A',
              ),
            },
          ],
        },
        payments: {
          columns: [
            { label: '', address: '' },
            { label: '', address: '' },
            { label: '', address: '' },
          ],
          rows: [
            {
              name: 'Reservation fee',
              tooltip: 'The fee to reserve your property, paid to the developer.',
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.payments.reservation_fee_in_cents),
              ),
            },
            {
              name: `Additional GetGround fees`,
              tooltip: `The additional fees that are owed to GetGround at exchange. For secondary stock, this is ${GG_SEARCH_FEE} + VAT (minimum ${MINIMUM_GG_SEARCH_FEE} + VAT) - for new builds there are no additional fees.`,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.payments.additional_gg_fees_in_cents),
              ),
              hideRow: action.payload[0].payments.package_price_in_cents,
            },
            {
              // to be deleted once old packages are removed
              name: `GG ${action.payload[0].payments.package} Package`,
              tooltip: `The cost of your ${action.payload[0].payments.package} package, including in-depth property analysis, end-to-end support and more.`,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.payments.package_price_in_cents),
              ),
              hideRow: !action.payload[0].payments.package_price_in_cents,
            },
            {
              name: 'Legal fees',
              tooltip: 'What you’ll pay your solicitors for conveyancing services.',
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.payments.legal_fees_in_cents),
              ),
            },
            {
              name: 'Deposit',
              tooltip: `The cash amount you'll pay for the property.`,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(
                  item.payments.deposit_in_cents - item.payments.reservation_fee_in_cents,
                ),
              ),
            },
            {
              name: 'Deposit %',
              tooltip: `The percentage of the property's total value you'll pay up-front in cash.`,
              values: action.payload.map(
                (item: PropertyAnalysisItem) =>
                  `${Big(item.payments.deposit_percentage * 100).toFixed(2)}%`,
              ),
            },
            {
              name: 'Total due at exchange',
              tooltip:
                'This is when you exchange contracts with the seller/developer, and your purchase becomes legally binding.',
              isBold: true,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.payments.total_due_at_exchange_in_cents),
              ),
            },
            {
              name: 'Mortgage amount',
              tooltip: `The total amount you’ll borrow from your lender.`,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.payments.mortgage_amount_in_cents),
              ),
            },
            {
              name: 'Mortgage arrangement fees',
              tooltip: `The cost of the lender's valuation of the property.`,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.payments.mortgage_arrangement_fees_in_cents),
              ),
            },
            {
              name: 'Mortgage broker fee',
              tooltip: 'Fees payable to your mortgage broker.',
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.payments.mortgage_broker_fee_in_cents),
              ),
            },
            {
              name: 'Deposit settlement',
              tooltip: `This payment makes up the difference between the deposit you paid at exchange, and the deposit you need to pay for your purchase based on your mortgage. Here’s an example. If you arrange a mortgage at 75% loan-to-value, you’ll need to fund the other 25% yourself. But, at exchange, you might’ve only put down a 20% deposit. In this instance, you’ll need to pay the difference (5%) as a deposit settlement.`,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.payments.deposit_settlement_in_cents),
              ),
            },
            {
              name: 'Stamp duty',
              tooltip: `For a purchase of a property, this is the estimated amount you'd pay in Stamp Duty Land Tax to the UK government - this assumes that you already own  property in the UK or elsewhere in the world. For a company purchase, this is an indicative value of stamp duty based on the agreed property value. The exact amount will be based on the final purchase price of the shares, which will be calculated based on the company’s net asset value.`,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.payments.stamp_duty_in_cents),
              ),
            },
            {
              name: 'Valuation fee',
              tooltip: `Fee paid to the lender for their valuation of the property.`,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.payments.valuation_fee_in_cents),
              ),
            },
            {
              name: 'Furniture pack',
              tooltip: `The cost of your furniture pack from one of our trusted partners. Excludes VAT.`,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.payments.furniture_pack_cost_in_cents),
              ),
            },
            {
              name: 'Total due before completion',
              tooltip: `This is when you pay for your property, get the keys, and can start earning rent on your property.`,
              isBold: true,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.payments.total_due_before_completion_in_cents),
              ),
            },
            {
              name: 'Total investment costs',
              tooltip: `The total cost of your initial investment in the property.`,
              isBold: true,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.payments.total_investment_in_cents),
              ),
            },
          ],
        },
        income: {
          columns: [
            { label: '', address: '' },
            { label: '', address: '' },
            { label: '', address: '' },
          ],
          rows: [
            {
              name: 'Gross rent per month',
              tooltip: `Total rent payable per month, without any deductions for your mortgage, ground rent, etc.`,
              isBold: true,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.income.gross_net_per_month_in_cents),
              ),
            },
            {
              name: 'Gross yield (%)',
              tooltip: `Total annual rental income before tax, as a percentage of the property's value.`,
              values: action.payload.map(
                (item: PropertyAnalysisItem) =>
                  `${Big(item.income.gross_yield_percentage * 100).toFixed(2)}%`,
              ),
            },
            {
              name: 'Ground rent',
              tooltip: `A rental charge paid to your property's freeholder or developer.`,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.income.ground_rent_in_cents),
              ),
            },
            {
              name: 'Service charge',
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.income.service_charge_in_cents),
              ),
            },
            {
              name: 'Lettings & management fee',
              tooltip: `Fees paid to GetGround for the day-to-day management of your property`,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.income.lettings_fee_in_cents),
              ),
            },
            {
              name: 'GetGround subscription',
              values: action.payload.map(
                (item: PropertyAnalysisItem) =>
                  `£${Big(item.income.subscription_fee_in_cents).div(100)}`,
              ),
            },
            {
              name: 'Mortgage payment',
              tooltip: `How much you’ll pay back to your lender each month for your mortgage.`,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.income.mortgage_payment_in_cents),
              ),
            },
            {
              name: 'Monthly net income (pre-tax)',
              tooltip: `Your monthly rental income before tax, minus your mortgage payment, lettings & management fees, any ground rent, and service charges.`,
              isBold: true,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.income.monthly_net_income_pre_tax_in_cents),
              ),
            },
            {
              name: 'Monthly net income (post-tax)',
              tooltip: `Your monthly rental income, minus tax, your mortgage payment, lettings & management fees, any ground rent, and service charges.`,
              values: action.payload.map((item: PropertyAnalysisItem) =>
                formatCurrency(item.income.monthly_net_income_post_tax_in_cents),
              ),
            },
          ],
        },
      }
    })
  },
})

export const { clearPropertyReportData, clearSinglePropertyReport } = propertyReportSlice.actions

export default propertyReportSlice.reducer
