import Cookies from 'js-cookie'
import { captureMessage } from '@sentry/react'
import { getApp, getApps, initializeApp } from 'firebase/app'
import { deleteToken, getMessaging, isSupported } from 'firebase/messaging'

export const fcmConfig = {
  vapidPublicKey: import.meta.env.VITE_FCM_PUBLIC_VAPID_KEY,
  initialiseConfig: {
    apiKey: import.meta.env.VITE_FCM_API_KEY,
    authDomain: import.meta.env.VITE_FCM_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FCM_PROJECT_ID,
    messagingSenderId: import.meta.env.VITE_FCM_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FCM_APP_ID,
  },
}

const app = getApps().length === 0 ? initializeApp(fcmConfig.initialiseConfig) : getApp()

export const messaging = async () => {
  const supported = await isSupported()

  if (!supported) {
    console.log('[Notification] Browser does not support push notifications.')
    return null
  }

  return getMessaging(app)
}

export const getNotificationPermission = () => {
  // iOS doesn't have `Notification` in `window`
  if (!('Notification' in window && 'PushManager' in window)) {
    captureMessage('[Notification] Browser does not support push notifications.')
    console.log('[Notification] Browser does not support push notifications.')
    return null
  }

  return Notification.permission
}

const NOTIFICATION_DISMISSED_COOKIE = 'notifications-dismissed'

const dismissalCookieName = (id: number) => `${NOTIFICATION_DISMISSED_COOKIE}-${id}`

export const getDismissalCookie = (userId: number): boolean => {
  return Cookies.get(dismissalCookieName(userId)) ?? false
}

export const setDismissalCookie = (userId: number) => {
  Cookies.set(dismissalCookieName(userId), true, { expires: 60 })
}

export const removeFCMToken = async () => {
  try {
    // Get current FCM Messaging instance
    const fcmMessaging = await messaging()

    if (fcmMessaging) {
      // Deletes the registration token associated with this Messaging instance and unsubscribes the Messaging instance from the push subscription.
      await deleteToken(fcmMessaging)
    }
  } catch {
    // none
  }
}
