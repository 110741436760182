import { useMutation } from '@tanstack/react-query'
import {
  createUserGroup,
  createUserGroupProperty,
  updateUserGroup,
  getFileUrl,
  uploadFile,
  deleteFile,
  type UserGroupUploadFilePayload,
  updateLandlordDocuments,
  deleteLandlordDocuments,
  recordLandlordFiles,
  RecordFile,
  DocumentWithExpenseResponse,
} from './routes'
import { queryClient } from 'src/utils/reactQueryConfig'
import { UserGroup } from 'src/types/UserGroup'
import { landlordKeys } from './queries'
import { propertyKeys } from '../property/queries'
import { DocumentWithExpense } from 'src/types/Document'

export const useCreateUserGroup = () =>
  useMutation({
    mutationFn: () => createUserGroup(),
  })

export const useUpdateUserGroup = ({ id }: { id: number }) =>
  useMutation({
    mutationFn: (data: Partial<UserGroup>) => updateUserGroup(id, data),
    onSuccess: (_, vars) => {
      queryClient.setQueryData(landlordKeys.userGroup(id), (oldData: UserGroup | undefined) => {
        if (!oldData) return oldData
        return { ...oldData, ...vars }
      })
      queryClient.invalidateQueries({ queryKey: landlordKeys.userGroups })
    },
  })

export const useGetFileUrl = (id: number) =>
  useMutation({
    mutationFn: (file: File) => getFileUrl(id, { filename: file.name, mime_type: file.type }),
  })

export const useUpdateFile = (id: number) =>
  useMutation({
    mutationFn: (file: UserGroupUploadFilePayload) => uploadFile(id, file),
    onSuccess: (_, data) => {
      queryClient.setQueryData(landlordKeys.userGroup(id), (oldData: UserGroup | undefined) => {
        if (!oldData) return oldData
        return { ...oldData, ...data }
      })
    },
  })

export const useDeleteFile = (id: number) =>
  useMutation({
    mutationFn: () => deleteFile(id),
  })

export const useCreateUserGroupProperty = () =>
  useMutation({
    mutationFn: ({ id }: { id: number }) => createUserGroupProperty(id),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: propertyKeys.properties,
        refetchType: 'all',
        exact: true,
      }),
  })

export const useDeleteLandlordDocuments = ({ userGroupID }: { userGroupID: number }) => {
  return useMutation({
    mutationFn: (data: string[]) => deleteLandlordDocuments(userGroupID, data),
    onSuccess: (_, vars) => {
      queryClient.cancelQueries(landlordKeys.landlord_documents(userGroupID))
      queryClient.setQueryData(
        landlordKeys.landlord_documents(userGroupID),
        (oldData: DocumentWithExpenseResponse | undefined) => {
          if (!oldData || !oldData?.files?.length) return
          let { files } = oldData
          files = files?.filter((doc) => !vars.includes(doc.id))
          return { files }
        },
      )
    },
  })
}

export const useUpdateLandlordDocuments = ({ userGroupID }: { userGroupID: number }) => {
  return useMutation({
    mutationFn: (data: DocumentWithExpense[]) => updateLandlordDocuments(userGroupID, data),
    onSuccess: (response) => {
      queryClient.cancelQueries(landlordKeys.landlord_documents(userGroupID))
      queryClient.setQueryData(
        landlordKeys.landlord_documents(userGroupID),
        (oldData: DocumentWithExpenseResponse | undefined) => {
          if (!oldData || !oldData?.files?.length) return response
          let { files } = oldData
          files = files.map((doc) => {
            const updatedDoc = response.files?.find((d) => d.id === doc.id)
            return updatedDoc || doc
          })
          return { files }
        },
      )
    },
  })
}

export const useRecordLandlordFiles = ({ userGroupID }: { userGroupID: number }) => {
  return useMutation({
    mutationFn: (files: RecordFile[]) => recordLandlordFiles(userGroupID, files),
    onSuccess: (response) => {
      queryClient.cancelQueries(landlordKeys.landlord_documents(userGroupID))
      queryClient.setQueryData(
        landlordKeys.landlord_documents(userGroupID),
        (oldData: DocumentWithExpenseResponse | undefined) => {
          if (!oldData || !oldData?.files?.length) return response
          let { files } = oldData
          files = [...(files ?? []), ...(response.files ?? [])]
          return { files }
        },
      )
    },
  })
}
