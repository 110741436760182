import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import * as propertyAPI from 'src/api/property/routes'
import { getRentalTransactions } from 'src/api/ggAPI'

interface GetPropertyProps {
  id: number
}

export const propertyKeys = {
  properties: ['properties'] as const,
  lenders: ['lenders'] as const,
  property: (id: number) => ['property', id] as const,
  property_mortgage: (ppID?: number) =>
    ppID ? ([...propertyKeys.property(ppID), 'mortgage'] as const) : [],
  rent_transactions: (companyID: number[] | number) => ['rent_transactions', companyID] as const,
  valuations: (ppID: number) => ['valuations', ppID] as const,
  capital_rental_growth: (postcode: string) => ['capital_rental_growth', postcode] as const,
  multi_properties_onboarding: (companyPropertyID: number) =>
    ['multi_properties_onboarding', companyPropertyID] as const,
  multi_properties_documents: (companyPropertyID: number) =>
    ['multi_properties_documents', companyPropertyID] as const,
  company_properties: ['company_properties'] as const,
}

export const useGetProperty = ({ id }: GetPropertyProps) =>
  useQuery({
    queryKey: propertyKeys.property(id),
    queryFn: () => propertyAPI.getProperty({ id }),
    enabled: Boolean(id),
    staleTime: 10 * 1000,
  })

export const useGetProperties = ({
  hasMultipleShareholders = null,
}: {
  hasMultipleShareholders?: boolean | null
}) =>
  useQuery({
    queryKey: propertyKeys.properties,
    queryFn: () => propertyAPI.getProperties(hasMultipleShareholders),
    staleTime: 10 * 1000,
  })

interface RentTransactionsProps {
  companyID: number[] | number
  enabled?: boolean
}

export const useGetRentTransactions = ({ companyID }: RentTransactionsProps) =>
  useQuery({
    queryKey: propertyKeys.rent_transactions(companyID),
    queryFn: () =>
      getRentalTransactions({
        companies: Array.isArray(companyID) ? companyID : [companyID],
        page: 1,
        items_per_page: 100,
      }),
    enabled: Array.isArray(companyID) ? !!companyID?.length : !!companyID,
  })

interface RentTransactionsInfiniteProps {
  companyID: number[]
  enabled?: boolean
  page?: number
  itemsPerPage?: number
}

export const useGetRentTransactionsInfinite = ({
  companyID,
  page = 1,
  itemsPerPage = 25,
}: RentTransactionsInfiniteProps) =>
  useInfiniteQuery(
    propertyKeys.rent_transactions(companyID),
    ({ pageParam = page }) =>
      getRentalTransactions({
        companies: companyID,
        page: pageParam,
        items_per_page: itemsPerPage,
      }),

    {
      enabled: !!companyID?.length,
      keepPreviousData: true,

      getNextPageParam: (lastPage, pages) => {
        // Calculate the next page based on the last page data
        const { num_transactions } = lastPage
        const totalPages = Math.ceil(num_transactions / itemsPerPage) // 10 records per page

        // If we have not reached the last page, return the next page number
        if (pages.length < totalPages) {
          return pages.length + 1
        }

        // Return undefined to indicate there are no more pages
        return undefined
      },
    },
  )
export const useGetPropertyMortgage = ({ ppID }: { ppID?: number }) =>
  useQuery({
    queryKey: propertyKeys.property_mortgage(ppID),
    queryFn: () => propertyAPI.getPropertyMortgage(ppID),
    enabled: !!ppID,
  })

export const useGetLenders = () =>
  useQuery({
    queryKey: propertyKeys.lenders,
    queryFn: () => propertyAPI.getLenders(),
    staleTime: 5 * 60 * 1000,
  })

interface GetValuationsProps {
  ppID: number
}

export const useGetValuations = ({ ppID }: GetValuationsProps) =>
  useQuery({
    queryKey: propertyKeys.valuations(ppID),
    queryFn: () => propertyAPI.getPropertyValuations(ppID),
    enabled: Boolean(ppID),
  })

interface CapitalGrowthProps {
  postcode: string
}

export const useGetCapitalRentalGrowth = ({ postcode }: CapitalGrowthProps) =>
  useQuery({
    queryKey: propertyKeys.capital_rental_growth(postcode),
    queryFn: () => propertyAPI.getCapitalGrowthByPostcode(postcode),
    enabled: Boolean(postcode),
  })

/* Get multi properties onboarding */
export const useGetMultiplePropertiesOnboarding = ({
  companyPropertyID,
}: {
  companyPropertyID: number
}) =>
  useQuery({
    queryKey: propertyKeys.multi_properties_onboarding(companyPropertyID),
    queryFn: () => propertyAPI.getMultiplePropertiesOnboarding(companyPropertyID),
    enabled: Boolean(companyPropertyID),
  })

/* Get multi properties onboarding legal documents */
export const useGetMultiplePropertiesDocuments = ({
  companyPropertyID,
}: {
  companyPropertyID: number
}) =>
  useQuery({
    queryKey: propertyKeys.multi_properties_documents(companyPropertyID),
    queryFn: () => propertyAPI.getMultiplePropertiesDocuments(companyPropertyID),
    enabled: Boolean(companyPropertyID),
  })

export const useGetCompanyProperties = () =>
  useQuery({
    queryKey: propertyKeys.company_properties,
    queryFn: () => propertyAPI.getCompanyProperties(),
    staleTime: 10 * 1000,
  })
