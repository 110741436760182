import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  getProductPaymentDetails as apiGetProductPaymentDetails,
  makeProductPaymentIntent as apiMakeProductPaymentIntent,
  updateProduct as apiUpdateProduct,
} from 'src/api/ggAPI'
import ProductPaymentDetails from 'src/types/ProductPaymentDetails'

interface InitialState {
  payment_details: ProductPaymentDetails | null
  selectedProductPaymentFailed: boolean
  selectedProductPaymentFailureReason: string
}

export const initialState: InitialState = {
  payment_details: null,
  selectedProductPaymentFailed: false,
  selectedProductPaymentFailureReason: '',
}

export const getProductPaymentDetails = createAsyncThunk(
  'products/getProductPaymentDetails',
  ({ productID }: { productID: number }) => apiGetProductPaymentDetails({ productID }),
)

export const makeProductPaymentIntent = createAsyncThunk(
  'products/makeProductPaymentIntent',
  ({ productID }: { productID: number }) => apiMakeProductPaymentIntent({ productID }),
)

export const updateProduct = createAsyncThunk(
  'products/updateProduct',
  ({
    productID,
    payload,
  }: {
    productID: number
    payload: { is_call_booked?: boolean; is_call_skipped?: boolean }
  }) => apiUpdateProduct({ productID, payload }),
)

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    resetPaymentDetails: () => initialState,
    setPaymentFailedOnProductPage: (state, action) => {
      state.selectedProductPaymentFailed = action.payload
    },
    setPaymentFailureReasonOnProductPage: (state, action) => {
      state.selectedProductPaymentFailureReason = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductPaymentDetails.fulfilled, (state, action) => {
      state.payment_details = action.payload
    })
  },
})

export const {
  resetPaymentDetails,
  setPaymentFailedOnProductPage,
  setPaymentFailureReasonOnProductPage,
} = productsSlice.actions

export default productsSlice.reducer
