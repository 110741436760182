import { useDispatch } from 'react-redux'
import { trackSnowplow } from 'src/state/slices/user.slice'

const useLandLordOnboardingAnalytics = () => {
  const dispatch = useDispatch()

  /* Personal Landlord onboarding screen */
  const startPersonalLandlordAnalytics = () => {
    dispatch(trackSnowplow({ category: 'pll', label: 'value-prop', action: 'click-start' }))
  }
  const learnMorePersonalLandlordAnalytics = () => {
    dispatch(trackSnowplow({ category: 'pll', label: 'value-prop', action: 'click-learn-more' }))
  }
  const goBackFromPersonalLandlordAnalytics = () => {
    dispatch(trackSnowplow({ category: 'pll', label: 'value-prop', action: 'click-go-back' }))
  }

  /* Eligibility screen */
  const continueEligibilityAnalytics = () => {
    dispatch(trackSnowplow({ category: 'pll', label: 'eligibility', action: 'click-continue' }))
  }
  const cancelEligibilityAnalytics = () => {
    dispatch(trackSnowplow({ category: 'pll', label: 'eligibility', action: 'click-cancel' }))
  }
  const goBackFromEligibilityAnalytics = () => {
    dispatch(trackSnowplow({ category: 'pll', label: 'eligibility', action: 'click-go-back' }))
  }

  /* Property ownership screen */
  const selectSoleOwnerShipAnalytics = () => {
    dispatch(trackSnowplow({ category: 'pll', label: 'ownership', action: 'click-sole-owner' }))
  }
  const selectSharedOwnerShipAnalytics = () => {
    dispatch(trackSnowplow({ category: 'pll', label: 'ownership', action: 'click-co-owned' }))
  }
  const saveAndExitFromPageShipAnalytics = (page: string) => {
    dispatch(trackSnowplow({ category: 'pll', label: page, action: 'click-save-and-exit' }))
  }
  const goBackFromOwnerShipAnalytics = () => {
    dispatch(trackSnowplow({ category: 'pll', label: 'ownership', action: 'click-go-back' }))
  }
  const crossIconOwnerAnalytics = () => {
    dispatch(trackSnowplow({ category: 'pll', label: 'owner', action: 'click-cross-icon' }))
  }

  /* Add new financial account */
  const addNewFinancialAccountAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'create-new-financial-account',
        action: 'click-start',
      }),
    )
  }

  const goBackFromNewFinancialAccountAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'create-new-financial-account',
        action: 'click-go-back',
      }),
    )
  }

  /* Multi Property ownership screen */
  const addSharedOwnerShipPropertyAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'confirm-add-co-owned-property',
        action: 'click-submit',
      }),
    )
  }
  const addSoleOwnerShipPropertyAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'confirm-add-sole-owner-property',
        action: 'click-submit',
      }),
    )
  }
  const clickOpenNewFinancialAccountRedirectAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'open-a-new-financial-account-screen',
        action: 'click',
      }),
    )
  }

  /* Add another property */
  const addAnotherPropertyAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'add-another-property',
        action: 'click',
      }),
    )
  }

  const finishedAddingPropertyAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'finished-adding-property',
        action: 'click',
      }),
    )
  }

  const goBackFromAnotherPropertyAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'add-another-property',
        action: 'click-go-back',
      }),
    )
  }

  const showPropertySuccessModalAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'show-property-added-success-modal',
        action: 'load',
      }),
    )
  }

  const closePropertySuccessModalAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'close-property-added-success-modal',
        action: 'click',
      }),
    )
  }

  const addPropertyFromSuccessModalAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'add-more-property-from-success-modal',
        action: 'click',
      }),
    )
  }

  /* Property address screen */
  const continuePropertyDetailsAnalytics = () => {
    dispatch(trackSnowplow({ category: 'pll', label: 'property-details', action: 'click-next' }))
  }
  const saveAndExitFromPropertyDetailsAnalytics = () => {
    dispatch(
      trackSnowplow({ category: 'pll', label: 'property-details', action: 'click-save-and-exit' }),
    )
  }
  const goBackFromPropertyDetailsAnalytics = () => {
    dispatch(trackSnowplow({ category: 'pll', label: 'property-details', action: 'click-go-back' }))
  }

  /* Property purchase details screen */

  const continuePropertyPurchaseAnalytics = () => {
    dispatch(
      trackSnowplow({ category: 'pll', label: 'property-purchase-details', action: 'click-next' }),
    )
  }
  const saveAndExitFromPropertyPurchaseAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'property-purchase-details',
        action: 'click-save-and-exit',
      }),
    )
  }
  const goBackFromPropertyPurchaseAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'property-purchase-details',
        action: 'click-go-back',
      }),
    )
  }

  /* Property review screen */
  const agreeGGTermsAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'property-details-review',
        action: 'click-gg-terms',
      }),
    )
  }
  const agreeModulrTermsAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'property-details-review',
        action: 'click-modulr-terms',
      }),
    )
  }
  const confirmReviewAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'property-details-review',
        action: 'click-confirm-and-submit',
      }),
    )
  }
  const saveAndExitFromReviewAnalytics = () => {
    dispatch(
      trackSnowplow({
        category: 'pll',
        label: 'property-details-review',
        action: 'click-save-and-exit',
      }),
    )
  }
  const goBackFromReviewAnalytics = () => {
    dispatch(
      trackSnowplow({ category: 'pll', label: 'property-details-review', action: 'click-go-back' }),
    )
  }

  return {
    startPersonalLandlordAnalytics,
    learnMorePersonalLandlordAnalytics,
    goBackFromPersonalLandlordAnalytics,
    continueEligibilityAnalytics,
    cancelEligibilityAnalytics,
    goBackFromEligibilityAnalytics,
    selectSoleOwnerShipAnalytics,
    selectSharedOwnerShipAnalytics,
    saveAndExitFromPageShipAnalytics,
    goBackFromOwnerShipAnalytics,
    crossIconOwnerAnalytics,
    addNewFinancialAccountAnalytics,
    goBackFromNewFinancialAccountAnalytics,
    addSharedOwnerShipPropertyAnalytics,
    addSoleOwnerShipPropertyAnalytics,
    clickOpenNewFinancialAccountRedirectAnalytics,
    addAnotherPropertyAnalytics,
    finishedAddingPropertyAnalytics,
    goBackFromAnotherPropertyAnalytics,
    showPropertySuccessModalAnalytics,
    closePropertySuccessModalAnalytics,
    addPropertyFromSuccessModalAnalytics,
    continuePropertyDetailsAnalytics,
    saveAndExitFromPropertyDetailsAnalytics,
    goBackFromPropertyDetailsAnalytics,
    continuePropertyPurchaseAnalytics,
    saveAndExitFromPropertyPurchaseAnalytics,
    goBackFromPropertyPurchaseAnalytics,
    agreeGGTermsAnalytics,
    agreeModulrTermsAnalytics,
    confirmReviewAnalytics,
    saveAndExitFromReviewAnalytics,
    goBackFromReviewAnalytics,
  }
}

export default useLandLordOnboardingAnalytics
