import { useEffect, useState } from 'react'

type Devices = 'lg' | 'md' | 'sm' | 'xs' | 'mobile' | 'desktop'

// MUI breakpoints
// Defined here but eventually could be moved to theme
const device = {
  lg: matchMedia('(max-width: 1919.95px)'),
  md: matchMedia('(max-width: 1279.95px)'),
  sm: matchMedia('(max-width: 959.95px)'),
  xs: matchMedia('(max-width: 599.95px)'),
  mobile: matchMedia('(max-width: 1000px)'),
  desktop: matchMedia('(max-width: 1200px)'),
} as { [key in Devices]: MediaQueryList }

const useBreakpoint = (breakpoint: Devices): boolean => {
  const deviceQuery = device[breakpoint]
  const [isMatch, setIsMatch] = useState<boolean>(deviceQuery.matches)

  useEffect(() => {
    const handleMatch = (event: MediaQueryListEvent): void => setIsMatch(event.matches)

    // Check for older browsers
    if (deviceQuery.addEventListener) deviceQuery.addEventListener('change', handleMatch)
    else deviceQuery.addListener(handleMatch)
    return (): void => {
      if (deviceQuery.removeEventListener) deviceQuery.removeEventListener('change', handleMatch)
      else deviceQuery.removeListener(handleMatch)
    }
  }, [deviceQuery])

  return isMatch
}

export default useBreakpoint
