import { useQuery } from '@tanstack/react-query'
import { getLandlordDocuments, getUserGroup, getUserGroups } from './routes'

export const landlordKeys = {
  userGroups: ['user_groups'] as const,
  userGroup: (id: number) => ['user_group', id] as const,
  landlord_documents: (userGroupID: number) =>
    [...landlordKeys.userGroup(userGroupID), 'documents'] as const,
}

export const useGetUserGroups = () =>
  useQuery({
    queryKey: landlordKeys.userGroups,
    queryFn: () => getUserGroups(),
    staleTime: 10 * 1000,
  })

export const useGetUserGroup = (id: number) =>
  useQuery({
    queryKey: landlordKeys.userGroup(id),
    queryFn: () => getUserGroup(id),
    enabled: !!id,
    staleTime: 3 * 1000,
  })

export const useGetLandlordDocuments = ({ userGroupID }: { userGroupID: number }) =>
  useQuery({
    queryKey: landlordKeys.landlord_documents(userGroupID),
    queryFn: () => getLandlordDocuments(userGroupID),
    enabled: Boolean(userGroupID),
  })
