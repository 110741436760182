import axios from 'axios'
import { DocumentWithExpense } from 'src/types/Document'
import { UserGroup, UserGroups } from 'src/types/UserGroup'
import { UserGroupFileResponse } from 'src/types/UserGroupFile'

export interface UserGroupUploadFilePayload {
  filename: string
  mime_type: string
  file_size_in_bytes: number
  object_name: string
}

export const createUserGroup = () => axios.post<{ user_group_id: number }>(`v3/user_groups`)

export const getUserGroups = () => axios.get<UserGroups>(`v3/user_groups`)

export const getUserGroup = (id: number) => axios.get<UserGroup>(`v3/user_groups/${id}`)

export const updateUserGroup = (id: number, data: Partial<UserGroup>) =>
  axios.put<UserGroup>(`v3/user_groups/${id}`, data)

export const createUserGroupProperty = (id: number) => axios.post(`v3/user_groups/${id}/properties`)

// user group photo
export const getFileUrl = (
  id: number,
  file: { filename: string; mime_type: string },
): Promise<UserGroupFileResponse> => axios.post(`v3/user_groups/${id}/photo/signed_url_v4`, file)

export const uploadFile = (id: number, file: UserGroupUploadFilePayload) =>
  axios.post(`v3/user_groups/${id}/photo`, file)

export const deleteFile = (id: number) => axios.delete(`v3/user_groups/${id}/photo`)

export interface DocumentWithExpenseResponse {
  files: DocumentWithExpense[]
}

export const getLandlordDocuments = (id: number): Promise<DocumentWithExpenseResponse> =>
  axios.get(`v3/user_groups/${id}/files`)

export const getLandlordDocumentsUrls = (
  userGroupID: number,
  ...ids: string[]
): Promise<DocUrl[]> =>
  axios.get(`/v3/user_groups/${userGroupID}/files/signed_url?id=${ids.join(',')}`)

export const deleteLandlordDocuments = (id: number, ids: string[]) =>
  axios.delete(`v3/user_groups/${id}/files?id=${ids.join(',')}`)

export const updateLandlordDocuments = (id: number, files: DocumentWithExpense[]) => {
  console.log('documents', files)
  return axios.patch<DocumentWithExpenseResponse>(`v3/user_groups/${id}/files`, {
    files: files.map((file) => ({
      id: file.id,
      filename: file.title,
      category: file.category,
      document_date: file.document_date,
      expense_details: file?.expense_details ?? null,
    })),
  })
}

export interface RecordFile {
  name: string
  objectName: string
  size: number
  category: string
  mimeType: string
  document_date?: string
  submissionID?: number
  expense_details?: null | {
    amount_in_cents: number
    category: string
    currency: string | 'GBP'
    description: string
    merchant_name: string
    paid_by_user_id: number
  }
}

export const recordLandlordFiles = (userGroupID: number, files: RecordFile[]) =>
  axios.post<DocumentWithExpenseResponse>(`v3/user_groups/${userGroupID}/files`, {
    files: files.map((file) => ({
      filename: file.name,
      object_name: file.objectName,
      file_size_in_bytes: file.size,
      category: file.category,
      mime_type: file.mimeType,
      document_date: file.document_date,
      document_submission_id: file.submissionID,
      expense_details: file?.expense_details ?? null,
    })),
  })
