import React, { useEffect } from 'react'
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom'
import { useTypedSelector } from 'src/state/reducers'
import { getJwtToken, isAuthorisedToken } from 'src/utils/auth'
import useSessionStorage from 'src/hooks/useSessionStorage'
import { AUTHENTICATED_ROUTES_WITHOUT_2FA } from 'src/config/constants'

interface With2FACheckProps {
  webSessionAccessible?: boolean
}

const with2FACheck = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const WithTwoFACheck: React.FC<P & With2FACheckProps> = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const user = useTypedSelector((state) => state.user)
    const token = getJwtToken()
    const { saveToSessionStorage } = useSessionStorage()
    const context = useOutletContext<{ webSessionAccessible?: boolean }>()
    const webSessionAccessible = props.webSessionAccessible ?? context.webSessionAccessible
    const userRequire2FASetup = user.require_2fa_setup

    const isCurrentRouteExemptFrom2FA = () =>
      AUTHENTICATED_ROUTES_WITHOUT_2FA.some((route) => location.pathname.startsWith(route))

    const does2FANeedSetup = () => userRequire2FASetup && !isUserOn2FASetupPage()

    const does2FANeedVerification = () => {
      const hasToken = token !== null
      const isTokenNotAuthorised = hasToken && !isAuthorisedToken(token)
      const has2FAMethods = user.two_factor_auth_method && user.two_factor_auth_method.length > 0
      return isTokenNotAuthorised && has2FAMethods
    }

    const isUserOn2FASetupPage = () =>
      ['/onboarding/sms-setup', '/qr-verify', '/sms'].some((path) =>
        location.pathname.startsWith(path),
      ) || location.search.includes('?is-onboarding')

    const redirectTo2FASetup = () => {
      saveToSessionStorage('redirectTo', location.pathname)
      navigate('/onboarding/sms-setup')
    }

    const redirectTo2FAVerification = () => {
      const verificationPath = user.two_factor_auth_method === 'totp' ? '/qr-verify' : '/sms'
      navigate(verificationPath)
    }

    useEffect(() => {
      if (does2FANeedVerification()) {
        redirectTo2FAVerification()
      } else if (!isCurrentRouteExemptFrom2FA() && does2FANeedSetup()) {
        redirectTo2FASetup()
      }
    }, [user, location.pathname, location.search, token, webSessionAccessible])

    return <WrappedComponent {...props} />
  }
  WithTwoFACheck.displayName = `With2FACheck(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`
  return WithTwoFACheck
}

export default with2FACheck
